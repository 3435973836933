import React, { Component, Fragment, useState } from "react";
import { Box, Typography, IconButton, Button, Grid, Chip, Grow, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { getCart, calcularValorTotalIItem, saveCart } from '../../utils/PedidoUtil'
import Fab from '@material-ui/core/Fab';
import { withSnackbar } from "notistack";
import { makeStyles } from '@material-ui/core/styles';
import Utils, { trunkStr, isEmpresaBloqueada } from '../../utils/Utils'
import AuthService from "../../services/AuthService.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomButton from '../generic/CustomButton'
import CardGenerico from '../generic/CardGenerico'
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as Pixel from '../../utils/FacebookPixelUtil';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import AlertDialog from "../generic/AlertDialog.js";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: theme.palette.secondary.main
        }
    },

}));

class ConfirmarPedido extends Component {

    calcularValorComDesconto = () => {
        let cart = getCart();
        const valorDesconto = cart.pedido.valorDesconto;
        cart.valorTotalPedido -= valorDesconto;
        saveCart(cart)
        this.updateState()
    }

    constructor(props) {
        super(props)

        this.removerDescontoCart();
        this.state = {
            cart: getCart(),
            configuracaoAgendamento: null,
            open: false,
            identificador: '',
            cupomDesconto: '',
            cupomValido: '',
            msgRetornoCupomDesconto: '',
            showInputCupom: false,
        }
        this.updateState = this.updateState.bind(this)
        this.retornarParaListagem = this.retornarParaListagem.bind(this)
        this.onClickAplicarCupomDesconto = this.onClickAplicarCupomDesconto.bind(this)
        this.handleChangeCupomDesconto = this.handleChangeCupomDesconto.bind(this)
        this.removerDescontoCart = this.removerDescontoCart.bind(this)
        this.handleRemoveItem = this.handleRemoveItem.bind(this)
    }

    componentDidMount() {
        this.removerDescontoCart();
        this.getConfigsAgendamento()
        if (Pixel.isPixelOn()) {
            Pixel.trackCustom("click on cart");
        }
        this.setState({ identificador: JSON.parse(localStorage.getItem('_profile')) })
    }

    onClickAplicarCupomDesconto() {
        this.removerDescontoCart()
        let service = new AuthService()
        let cupom = this.state.cupomDesconto
        this.setState({ msgRetornoCupomDesconto: '' })

        if (cupom !== '') {
            let cart = getCart()

            const { valorTotalPedido } = this.props;
            cart.pedido.valorTotalPedido = valorTotalPedido

            service
                .post(`/cupomDesconto/aplicar/${this.state.cupomDesconto}`, cart.pedido)
                .then(resp => {

                    if (resp.cupomDesconto?.tipoCupom !== 'LOCAL') {
                        this.setState({ cupomValido: 'N' })
                        this.setState({ msgRetornoCupomDesconto: "Cupom válido somente para pedidos delivery." })
                        return;
                    } else if (resp.cupomDesconto?.ignorarProdutosPromocao === true) {
                        this.setState({ cupomValido: 'N' })
                        this.setState({ msgRetornoCupomDesconto: "Cupom não é válido para produtos em promoção." })
                        return;
                    }

                    this.setState({ cupomValido: 'S' })

                    cart.pedido.valorDesconto = resp.valorDesconto
                    cart.pedido.cupomDesconto = resp.cupomDesconto
                    cart.valorTotalPedido -= resp.valorDesconto
                    saveCart(cart)
                    this.setState({ cart })
                    this.setState({ msgRetornoCupomDesconto: 'Cupom de desconto válido' })
                }

                ).catch(err => {
                    this.setState({ cupomValido: 'N' })

                    if (err.response !== undefined) {
                        err.response.json()
                            .then(elem => {
                                console.log(elem.errorMessage)
                                this.setState({ msgRetornoCupomDesconto: elem.errorMessage })
                            });
                    }
                })
        } else {
            this.setState({ cupomValido: '' })
        }
    }

    handleChangeCupomDesconto(event) {
        let cupom = String(event.target.value).toUpperCase()
        this.setState({ cupomDesconto: cupom })

        if (!this.state.cupomDesconto) {
            this.setState({ cupomValido: '' })
        }
    }

    updateState() {
        this.setState({ cart: getCart() })
    }

    retornarParaListagem() {
        this.props.history.replace("/");
    }

    removerDescontoCart() {
        let cart = getCart()
        cart.pedido.valorDesconto = 0
        cart.pedido.cupomDesconto = null
        saveCart(cart)
    }

    //Utilizada na SnackBar para gerar o botão de remover os itens
    remover = (snackMsg) => (
        <Fragment>
            <Button size="small" color="inherit"
                onClick={() => {
                    this.removerItens();
                    this.props.closeSnackbar(snackMsg);
                }
                } >
                Remover itens
            </Button>
        </Fragment>
    );

    removerItens = () => {
        this.removerDescontoCart();
        // this.calcularValorComDesconto()

        let cart = getCart();

        cart.pedido.itens = cart.pedido.itens.filter(item => (item.remover !== true)); //remove os itens marcados como desatualizados

        this.props.enqueueSnackbar("Produtos desatualizados foram removidos", { variant: "info" });


        saveCart(cart); // salva as informações atualizadas no cart
        this.updateState(); // da um update visual nas informações mostradas
    }

    atualizaItens = (produtos) => { //função para atualizar os itens
        let cart = getCart();

        this.props.enqueueSnackbar("Existem itens desatualizados, por favor os remova e adicione novamente ao carrinho antes de prosseguir",
            {
                variant: 'error',
                action: this.remover,
                autoHideDuration: 5500,
            })

        produtos.map(produto => { //pega os produtos atualizados vindos do banco e verifica se estão pausados ou se o grupo está pausado
            cart.pedido.itens.map((item, index) => {
                if (produto.id === item.produto.id) {
                    item.remover = true;
                }
                return null;
            });
            return null;
        });

        saveCart(cart);//salva as informações atualizadas no cart
        this.updateState();//da um update visual nas informações mostradas
        this.calcularValorComDesconto()
    }

    verificarPedido = (pedido) => { //Verifica no banco de dados se os produtos estão atualizados
        let service = new AuthService()

        service
            .post("/pedido/verificarItens", pedido)
            .then(resp => {

                if (resp.length > 0) {//caso os itens estejam desatualizados gera uma snackbar para avisar o cliente com um botão para atualizar os itens
                    this.atualizaItens(resp)
                } else {//caso os itens estejam atualizados continua com o processo normal
                    this.props.history.push("/dadoUser");
                }
            }).catch(err => {
                console.error("Falha ao verificar itens", err)
            })

    }

    handleStep() {

        if (isEmpresaBloqueada()) {
            this.props.history.push("/errorSistemaBloqueado")
            return;
        }
        let cart = getCart()

        this.verificarPedido(cart.pedido);//chama a função que verifica se os itens estão atualizados
    }

    statusLojaFechada() {
        let identificador = this.state.identificador
        if (identificador.tipo === "DELIVERY") {
            return this.state.configuracaoAgendamento?.situacao === "N" && AuthService._statusLoja() === "FECHADO"
        } else {
            return AuthService._statusLoja() === "FECHADO"
        }
    }

    getConfigsAgendamento = () => {
        var service = new AuthService();

        service
            .get("/configurarAgendamento/configuracaoAtual")
            .then(resp => {
                this.setState({ configuracaoAgendamento: resp });
            })
    }

    handleOpenChamado() {//chama a página de chamado de garçom
        this.props.history.push("/chamarGarcom")
    }

    handleRemoveItem() {
        this.removerDescontoCart();
        this.updateState()
    }

    render() {
        const { cart, identificador, cupomValido, msgRetornoCupomDesconto, showInputCupom } = this.state;
        const chamarGarcom = identificador ? identificador.empresa.chamarGarcom : false;
        const isDelivery = identificador.tipo === "DELIVERY"

        let styles = {
            total: {
                fontFamily: "Bebas Neue",
                fontSize: "18px",
                fontWeight: "500",
                textTransform: "uppercase"
            }
        }
        return (
            <CardGenerico
                leftButtonClick={() => this.props.history.replace("/")}
                rightButtonClick={event => this.retornarParaListagem(event)}
                cardContent={
                    <div>
                        {!cart.temItens() ? (
                            <Box display="flex" justifyContent="center" p={2} >
                                <Typography >
                                    Nenhum item adicionado  :(
                                </Typography>

                            </Box>
                        ) : null}

                        <GridItensPedido pedido={cart.pedido} updateState={this.updateState} onRemoveItem={this.handleRemoveItem} />

                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Fab
                                onClick={this.retornarParaListagem}
                                variant="extended"
                                size="medium"
                                color="primary"
                                style={{ fontSize: "10px" }}
                            >
                                <Typography style={{ fontSize: "12px" }}>
                                    {cart.temItens() ? "Adicionar algo mais?" : "Adicionar um item"}
                                </Typography>
                            </Fab>

                        </Box>

                    </div>
                }

                cardActions={
                    <div>
                        {identificador?.viewOnly && (
                            <div>
                                <Box display="block" pt={2} pb={3}>
                                    <Typography
                                        display="block"
                                        align="center"
                                        color="primary"
                                        variant="body1"
                                    >
                                        Nosso cardápio está em modo de visualização <span role="img" aria-label="sadFace">🥺</span>
                                    </Typography>
                                    <Box p={1}>
                                        <Typography
                                            align="center"
                                            color="primary"
                                            variant="subtitle2"
                                        >
                                            Mas fique tranquilo
                                        </Typography>
                                    </Box>
                                    <Typography
                                        align="center"
                                        color="primary"
                                        variant="body1"
                                    >
                                        Para finalizar, chame o garçom ou faça seu pedido direto no balcão <span role="img" aria-label="claps">🙌👏</span>
                                    </Typography>

                                    {!isDelivery && chamarGarcom ? (
                                        <Box pt={2} display="flex" justifyContent="center">
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                onClick={() => {
                                                    this.handleOpenChamado();
                                                }}
                                                startIcon={<NotificationsActiveIcon fontSize="small" />}
                                            >
                                                Chamar Garçom
                                            </Button>
                                        </Box>
                                    ) : ""}
                                </Box>
                            </div>
                        )}
                        {!isDelivery && !showInputCupom ? (
                            <div>
                                <Box textAlign="center">
                                    <Chip
                                        onClick={() => this.setState({ showInputCupom: true })}
                                        color="primary"
                                        label="Adicionar cupom de desconto?"
                                        style={{ fontSize: '14px', marginBottom: '30px' }}
                                    />
                                </Box>
                            </div>
                        ) : null}
                        <div>
                            <Grid
                                spacing={2}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                {!isDelivery && showInputCupom ? (
                                    <Grid item xs={10} align="center">
                                        <Grow in={true}>
                                            <TextField
                                                label="Informe seu cupom"
                                                name="cupomDesconto"
                                                value={this.state.cupomDesconto}
                                                fullWidth
                                                onChange={event => this.handleChangeCupomDesconto(event)}
                                                variant="outlined"
                                                inputProps={{ maxLength: 20 }}
                                            />
                                        </Grow>
                                    </Grid>
                                ) : null}

                                {!isDelivery && showInputCupom ? (
                                    <Grid item xs={2} align="center">
                                        <Grow in={true} timeout={1000}>
                                            <IconButton onClick={this.onClickAplicarCupomDesconto}>
                                                <ThumbUpIcon />
                                            </IconButton>
                                        </Grow>
                                    </Grid>
                                ) : null}
                            </Grid>

                        </div>

                        <div>
                            <Grid item xs={12} align="center">
                                {cupomValido !== '' ? (
                                    <Chip
                                        onClick={() => this.setState({ openMsgRetornoCupom: true })}
                                        icon={cupomValido === 'S' ? <MoodIcon /> : <MoodBadIcon />}
                                        label={cupomValido === 'S' ? 'Legal! Olha o descontão aí.' : 'Cupom é inválido. Clique para saber o motivo'}
                                        color={cupomValido === 'S' ? 'primary' : 'secondary'}
                                        variant="outlined"
                                        style={{
                                            marginTop: cupomValido === "S" ? "15px" : "10px",
                                        }}
                                    />
                                ) : null}

                                < AlertDialog
                                    open={this.state.openMsgRetornoCupom}
                                    handleClose={() => this.setState({ openMsgRetornoCupom: false })}
                                    title=""
                                    content={msgRetornoCupomDesconto}
                                />
                            </Grid>
                            <Box style={{ display: "block", marginTop: "10px", textAlign: 'center' }}>
                                <Typography style={styles.total} color="primary">
                                    {`TOTAL ${Utils.formatNumber(cart.valorTotalPedido)}`}
                                </Typography>
                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    disabled={(!cart.temItens() || this.statusLojaFechada() || identificador?.viewOnly)}
                                    fullWidth
                                    onClick={event => this.handleStep(event)}
                                    style={{ textAlign: 'center', marginTop: '10px', justifyContent: 'center' }}
                                >
                                    {this.statusLojaFechada() ? 'OPA! ESTAMOS FECHADOS 🥺' : "Finalizar Pedido"}
                                </CustomButton>
                            </Box>
                        </div>
                    </div>
                }
            >
            </CardGenerico>
        );
    }
}

function GridItensPedido({ pedido, updateState, onRemoveItem }) {
    const classes = useStyles()
    const [openConfirm, setOpenConfirm] = useState(false)
    const [uuidItem, setUuidItem] = useState(null)

    const valorTotalItem = (item) => {
        return calcularValorTotalIItem(item)
    }

    const handleClickExcluir = (uuid) => {
        setOpenConfirm(true)
        setUuidItem(uuid)
    }

    const handleConfirmExcluir = () => {
        getCart().excluirItemPedidoPeloUUID(uuidItem)
        setOpenConfirm(false)
        setUuidItem(null)
        updateState()
        onRemoveItem()
    }

    let styles = {
        fonte: {
            fontFamily: "Bebas Neue",
            padding: '0 4px'

        },
        quantidade: {
            opacity: ".8",
            fontFamily: "Bebas Neue",
            fontWeight: "500",
            fontSize: "15px"
        }
    }

    return (
        <React.Fragment>
            <Table size="small" aria-label="itens do carrinho" className={classes.table}>
                <TableBody>

                    {pedido.itens.map((item) => (
                        <TableRow selected={item.remover}
                            key={item.uuid}
                            className={classes.tableRow}>
                            <TableCell>
                                {item.quantidade}x
                                <Typography color="primary" display="inline"
                                    variant="body1" style={styles.fonte} >
                                    {trunkStr(item.produto.nome, 40)}
                                </Typography>

                                {item.remover && (
                                    <Typography color="error">
                                        (desatualizado)
                                    </Typography>
                                )}

                                {item.subItens ? item.subItens.map(subItem => (
                                    <Box key={`subItem-${subItem.uuid}`}>
                                        <Typography variant="caption" style={{ fontSize: '10px' }}>
                                            ({subItem.quantidade}) {subItem.descricao}
                                        </Typography>
                                    </Box>
                                )) : null}

                                {item.observacao ? (
                                    <Typography variant="caption" display="block" gutterBottom >
                                        Obs: {item.observacao}
                                    </Typography>
                                ) : ""}
                            </TableCell>

                            <TableCell align="right" style={styles.fonte}>
                                {Utils.formatNumber(valorTotalItem(item))}
                            </TableCell>

                            <TableCell style={{ whiteSpace: "nowrap" }} align="right" padding="none">
                                <IconButton onClick={() => { handleClickExcluir(item.uuid) }} size="small">
                                    <DeleteIcon />
                                </IconButton>


                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <AlertDialogConfirDelete
                open={openConfirm}
                handleConfirm={handleConfirmExcluir}
                handleCancel={() => { setOpenConfirm(false) }} />
        </React.Fragment>
    )
}

function AlertDialogConfirDelete({ open, handleConfirm, handleCancel }) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deseja remover o item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Não
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Quero sim
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withSnackbar(withRouter(ConfirmarPedido));



