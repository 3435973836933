import React, { Component } from 'react'
import { Grid, Box, TextField, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import Robo from "../../../imagens/RoboCozinheiro.svg"
import AuthService from "../../../services/AuthService"
import MaskedInput from 'react-text-mask';
import Utils, { onlyDigits } from '../../../utils/Utils';
import Agendamento from './Agendamento';

export class Nome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            telefoneValidationMsg: "",
            nomeValidationMsg: "",
            formaRetirada: [],
            buscandoFormasRetirar: false,
            tipoValidationMessage: ""
        }
    }

    componentDidMount() {
        this.handleChange = this.handleChange.bind(this);

        let service = new AuthService()
        let idEmpresa = AuthService.getProfile().empresa.id

        this.setState({ buscandoFormasRetirar: true })
        service.get("/empresa/getOnly/" + idEmpresa + "?atributos=formaRetirada")
            .then(resp => {
                let formaRetirada = []
                try {
                    formaRetirada = JSON.parse(resp.formaRetirada || "[]")
                } catch (err) {
                    console.error("Forma de retirada do pedido na empresa não configurada ou incorreta.", err)
                }
                this.setState({ formaRetirada });
                this.setState({ buscandoFormasRetirar: false })
            }).catch(err => {
                console.error(err);
                this.setState({ buscandoFormasRetirar: false })
            })
    }

    handleChange(event) {

        if (event.target.name === "nome") {
            if (!event.target.value) {
                this.setState({ nomeValidationMsg: "Não pode ser vazio" })
            } else {
                this.setState({ nomeValidationMsg: "" })
            }
        }

        if (event.target.name === "telefone") {
            var telefoneNumbersOnly = onlyDigits(event.target.value);
            if (!event.target.value) {
                this.setState({ telefoneValidationMsg: "Não pode ser vazio" })
            } else {
                if (telefoneNumbersOnly.length < 10 || telefoneNumbersOnly.length > 11) {
                    this.setState({ telefoneValidationMsg: `Precisa ter pelo menos 10 dígitos (10 para telefone fixo e 11 para celular)` })
                } else {
                    this.setState({ telefoneValidationMsg: "" })
                }
            }
        }

        if (event.target.name === 'tipoDelivery') {
            let empresa = JSON.parse(localStorage.getItem("_profile")).empresa;
            let cart = JSON.parse(localStorage.getItem("_cart"));

            if (event.target.value === "ENTREGAR" && empresa.valorMinimo > cart.valorTotalPedido) {
                console.log("valor do pedido menos q o valor minimo");
                this.setState({ tipoValidationMessage: `valor minimo para entrega é de: ${Utils.formatNumber(empresa.valorMinimo)}` })
            } else {
                this.setState({ tipoValidationMessage: `` })
            }

        }


        this.props.handleChange(event);
    }

    TextMaskCustom(props) {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(xx) x xxxx-xxxx"
                placeholderChar={'\u2000'}
            />
        );
    }

    render() {

        const { nomeValidationMsg, formaRetirada, buscandoFormasRetirar, tipoValidationMsg } = this.state;
        const permiteEntregar = formaRetirada.indexOf("D") >= 0;
        const permiteRetirarLocal = formaRetirada.indexOf("L") >= 0;
        const permiteConsumirLocal = formaRetirada.indexOf("C") >= 0;
        return (
            <React.Fragment>


                <Grid
                    spacing={2}
                    container
                    direction="row" >

                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <Grid width="100%">
                            <Box style={{ margin: "0 auto" }}>
                                <img src={Robo} style={{ maxHeight: "20vh" }} alt="" />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography align="center">
                            <strong>Poderia me passar seu nome e seu telefone?</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            label="Nome completo"
                            name="nome"
                            value={this.props.pessoa.nome}
                            fullWidth
                            error={nomeValidationMsg ? true : false}
                            onChange={event => this.handleChange(event)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Telefone"
                            name="telefone"
                            variant="outlined"
                            value={this.props.pessoa.telefone}
                            fullWidth
                            onChange={event => this.handleChange(event)}
                            InputProps={{
                                inputComponent: this.TextMaskCustom,
                                value: this.props.pessoa.telefone
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box pt={1}>
                            <FormControl component="fieldset">
                                <RadioGroup value={this.props.tipoDelivery} name="tipoDelivery" onChange={event => this.handleChange(event)} >
                                    {permiteEntregar ?
                                        <FormControlLabel value="ENTREGAR" control={<Radio />} label="Quero receber em casa" /> : null}

                                    {permiteRetirarLocal ?
                                        <FormControlLabel value="RETIRAR_LOCAL" control={<Radio />} label="Quero retirar no local" /> : null}

                                    {permiteConsumirLocal ?
                                        <FormControlLabel value="CONSUMIR_LOCAL" control={<Radio />} label="Quero consumir no local" /> : null}
                                </RadioGroup>
                            </FormControl>

                            {buscandoFormasRetirar ? "Carregando...." : (
                                ((!permiteEntregar && !permiteRetirarLocal) ?
                                    <React.Fragment>
                                        <Typography
                                            display="block"
                                            align="center" color="primary"
                                            variant="body1">
                                            Nosso cardápio esta em modo consulta 🥺
                                        </Typography>
                                        <Box p={1}>
                                            <Typography
                                                align="center"
                                                color="primary"
                                                variant="subtitle2">
                                                Mas fique tranquilo
                                            </Typography>
                                        </Box>
                                        <Typography
                                            align="center"
                                            color="primary"
                                            variant="body1">
                                            Para finalizar, chame o garçom ou faça seu pedido direto no balcão 🙌👏
                                        </Typography>
                                    </React.Fragment>
                                    : null)
                            )}


                            {tipoValidationMsg ? (
                                <Typography>
                                    {tipoValidationMsg}
                                </Typography>
                            ) : null}
                        </Box>

                    </Grid>

                    {this.props.configuracaoAgendamento?.situacao === "A" || this.props.configuracaoAgendamento?.situacao === "NA" ? (
                        <Grid item xs={12}>
                            <Agendamento
                                configuracaoAgendamento={this.props.configuracaoAgendamento}
                                handleChangeDiaEHorario={this.props.handleChangeDiaEHorario}
                                selectedTimestampScheduleParam={this.props.selectedTimestampSchedule} />
                        </Grid>
                    ) : null}

                </Grid>
            </React.Fragment>
        )
    }
}

export default Nome
