import React, { useMemo } from 'react'
import { Box, Chip, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core"

function RadioFormMethod({ type, formaPagamentoList, formaPagamentoStr, handleChoosePayment }) {
    // realiza o cache do filtro por tipo
    // retornando apenas os pagamentos referentes a ele
    const filteredPagamentos = useMemo(() => {
        if (type === 'online') {
            return formaPagamentoList
                .filter(item => item?.tipoPagamento === type)
        }

        if (type === 'local') {
            return formaPagamentoList
                .filter(item => !item.tipoPagamento || item?.tipoPagamento === type)
        }

        return []
    }, [type, formaPagamentoList])

    return (
        <FormControl component="fieldset" fullWidth>
            <RadioGroup
                name="formaPagamentoStr"
                value={formaPagamentoStr}
                onChange={handleChoosePayment}>
                {filteredPagamentos.map(elem =>
                    <Box key={elem.id} display="flex" justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                            control={<Radio />}
                            label={elem.descricao}
                            value={JSON.stringify(elem)} />
                        {elem?.tipoPagamento === 'online' && (
                            <Chip variant="outlined" color="secondary" label="Online" />
                        )}
                    </Box>
                )}
            </RadioGroup>
        </FormControl>
    )
}

export function RadioFormPagamento({ formaPagamentoList, formaPagamentoStr, handleChoosePayment }) {

    return (
        <Box pt={1}>
            <Typography align="center">
                <strong>Selecione o método de pagamento</strong>
            </Typography>

            <Box pt={1}>
                <RadioFormMethod
                    type="local"
                    formaPagamentoStr={formaPagamentoStr}
                    formaPagamentoList={formaPagamentoList}
                    handleChoosePayment={handleChoosePayment} />
                <RadioFormMethod
                    type="online"
                    formaPagamentoStr={formaPagamentoStr}
                    formaPagamentoList={formaPagamentoList}
                    handleChoosePayment={handleChoosePayment} />
            </Box>
        </Box>
    )
}