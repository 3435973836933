import React, { useEffect, useState } from "react"
import AuthService from "../../services/AuthService"
import { Box, Typography } from "@material-ui/core"
import HorarioAbertura from "./HorarioAbertura"

export default function StatusLoja() {
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState(AuthService._statusLoja())

    useEffect(() => {
        function getStatusLoja() {
            console.log('verificando status loja')
            const service = new AuthService()

            service.getEmpresaStatusLoja(resp => setStatus(resp.statusAbertura))
        }

        getStatusLoja()

        window.addEventListener('focus', getStatusLoja)

        return () => {
            window.removeEventListener('focus', getStatusLoja)
        }
    }, [])

    function handleChangeDialog() {
        setOpen(!open)
    }

    return (
        <>
            <HorarioAbertura open={open} handleChangeDialog={handleChangeDialog} />

            <Box display="flex" justifyContent="center">
                {status === "ABERTO" ? (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ color: "#66bb6a", cursor: "pointer" }}
                        onClick={handleChangeDialog}>
                        aberto agora
                    </Typography>
                ) : (
                    <Typography
                        color="error"
                        variant="body2"
                        style={{ cursor: "pointer" }}
                        onClick={handleChangeDialog}>
                        fechado
                    </Typography>
                )}
            </Box>
        </>
    )
}

