import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Grid, Box } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function InformacoesProduto(props) {
  const produto = props.produto
  const open = props.open

  const handleClose = () => {
    props.close()
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        </DialogTitle>
        <DialogContent>
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center">

            <Grid item style={{ textAlign: 'center' }} xs={12}>
              <Box>
                <Typography color="primary">DESCRIÇÃO</Typography>
              </Box>

            </Grid>
            <Grid item style={{ textAlign: 'center' }} xs={12}>
              <Typography style={{ fontSize: "13px" }}>
                {produto.descricao ? (
                  produto.descricao
                ) : "Sem informações sobre esse produto"}
              </Typography>
            </Grid>

            {
              (produto.fichaTecnica) ? (
                <React.Fragment>
                  <Grid item style={{ textAlign: 'center' }} xs={12} >
                    <Box pt={2}>
                      <Typography color="primary" > INGREDIENTES </Typography>
                    </Box>
                  </Grid>
                  <Grid item style={{ textAlign: 'center' }} xs={12}>
                    <Typography style={{ fontSize: "13px" }}> {produto.fichaTecnica} </Typography>
                  </Grid>
                </React.Fragment>
              ) : null
            }

            {produto.advertencia ? (
              <Grid item style={{ textAlign: 'center', marginTop: "5%" }} xs={12}  >
                <Box style={{ backgroundColor: "rgb(255, 244, 229)" }} p={2}>
                  <Typography variant="body1"  >
                    {produto.advertencia}
                  </Typography>
                </Box>
              </Grid>
            ) :
              null
            }

          </Grid>

        </DialogContent>

      </Dialog>
    </div>
  );
}