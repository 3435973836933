import React from "react";
import Chip from '@material-ui/core/Chip';
import StarIcon from '@material-ui/icons/Star';

function PromoChip(props) {
    return (
        <Chip color="primary" size="small" label={props.label || "promo"} icon={<StarIcon />} />
    )
}

export default PromoChip;
