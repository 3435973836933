import React from 'react'
import { useSelector } from 'react-redux'

import clsx from 'clsx'
import { Box, makeStyles, Typography } from '@material-ui/core'
import CartaoFrente from '../../imagens/cartao-frente.svg'
import CartaoVerso from '../../imagens/cartao-verso.svg'

const useStyles = makeStyles((theme) => ({

  cardContainer: {
    width: '100%',
    minHeight: '150px',
    transition: '0.5s ease-in-out',
    transform: 'rotateY(0)',
    transformStyle: 'preserve-3d',
    marginBottom: theme.spacing(3) + 'px',
    display: 'flex',
    justifyContent: 'center',

    '&.active': {
      transform: 'rotateY(180deg)',
    }
  },

  cardFront: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    }
  },

  cardBack: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    transform: 'translateZ(-1px)',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    }
  },

  cardNumber: {
    position: 'absolute',
    left: '27px',
    bottom: '35px',
    textShadow: '1px 1px 0 #fff',
  },

  cardNome: {
    position: 'absolute',
    left: '27px',
    bottom: '16px',
    textShadow: '1px 1px 0 #fff',
    textTransform: 'uppercase',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    fontSize: '8px',
  },

  cardCcv: {
    position: 'absolute',
    left: '20px',
    bottom: '73px',
    textShadow: '1px 1px 0 #fff',
    transform: 'rotateY(190deg)',
  },

  cardExpiryDate: {
    position: 'absolute',
    left: '20px',
    bottom: '12px',
    textShadow: '1px 1px 0 #fff',
    textTransform: 'uppercase',
    transform: 'rotateY(190deg)',
  },

}))

export function CreditCardViewer({ turn }) {
  const classes = useStyles()
  const { form } = useSelector(state => state.payment)

  return (
    <Box className={clsx(classes.cardContainer, turn ? 'active' : '')}>
      <Box className={classes.cardFront}>
        <Typography variant="overline" className={classes.cardNumber}>{form.number}</Typography>
        <Typography variant="overline" className={classes.cardNome}>{form.holderName}</Typography>
        <img src={CartaoFrente} alt="" />
      </Box>
      <Box className={classes.cardBack}>
        <Typography variant="overline" className={classes.cardCcv}>{form.ccv}</Typography>
        <Typography variant="overline" className={classes.cardExpiryDate}>{form.expiryDate}</Typography>
        <img src={CartaoVerso} alt="" />
      </Box>
    </Box>
  )
}