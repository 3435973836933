import React, { Component } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CardGenerico from '../../generic/CardGenerico'
import CustomButton from '../../generic/CustomButton'
import TempoPreparo from '../../generic/TempoPreparo'
import RoboMaps from '../../../imagens/IconeRoboGarconMaps.svg'
import RoboDelivery from '../../../imagens/IconeRoboDelivery.svg'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { getUltimoPedido, getPerfile } from '../../../utils/PedidoUtil';
import Alert from '@material-ui/lab/Alert';

export class FinalizacaoDelivery extends Component {

    retornarParaListagem = () => {
        this.props.history.replace("/");
    }

    render() {
        let ultimoPedido = getUltimoPedido()

        return (
            <CardGenerico
                rightButtonClick={event => this.retornarParaListagem(event)}
                cardContent={
                    ultimoPedido.tipoDelivery === "ENTREGAR" ? (
                        <EntregarEmCasa />
                    ) : (
                        <RetirarNoLocal />
                    )
                }

                cardActions={
                    <CustomButton
                        variant="contained"
                        size="large" color="secondary"
                        fullWidth
                        onClick={event => this.retornarParaListagem(event)} >
                        Voltar ao cardápio
                    </CustomButton>
                }

            />
        )
    }
}

const RetirarNoLocal = () => {
    let empresa = getPerfile().empresa;
    let enderecoSimples = `${empresa.endereco}, ${empresa.numero}`
    let enderecoCompleto = `${enderecoSimples} - ${empresa.municipio.nome}, ${empresa.estado.sigla}`

    return (
        <React.Fragment>

            <Box style={{ margin: "0 auto" }}>
                <img alt='robo-maps' src={RoboMaps} style={{ maxHeight: "25vh" }} />
            </Box>

            <Box pt={6}>
                <Grid
                    container
                    direction="row" >
                    <Grid item xs={12}>
                        <Typography align="center" display="block">
                            <strong>Opa! Seu pedido já foi recebido e confirmado</strong>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} >
                        <Box p={3}>
                            <Typography align="center" variant="button" display="block"   >
                                Retirar no endereco
                            </Typography>

                            <Typography align="center" variant="caption" display="block"   >
                                Rua {empresa.endereco}, {empresa.numero} - {empresa.bairro}
                            </Typography>

                            <Typography align="center" variant="caption" display="block" >
                                {empresa.municipio.nome} - {empresa.estado.sigla}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} >
                        <Box display="flex" justifyContent="center">
                            <CustomButton href={`https://www.google.com/maps/place/${enderecoCompleto}`}
                                target="_blank" variant="outlined" color="primary" >
                                <LocationOnIcon color="secondary" /> Ver no Google Maps
                            </CustomButton>

                        </Box>
                    </Grid>

                </Grid>
            </Box>


        </React.Fragment>
    )
}

const EntregarEmCasa = () => {
    let ultimoPedido = getUltimoPedido()
    let empresa = getPerfile().empresa;
    let foneWhatsapp = empresa.foneWhatsapp || "";
    foneWhatsapp = "55" + foneWhatsapp;
    const mensagem = `Acabei de realizar o pedido ${ultimoPedido.identificadorSecundario} no qrpedir. E gostaria de saber o status dele!`;


    return (
        <div>

            <Box style={{ margin: "0 auto" }}>
                <img alt='robo-delivery' src={RoboDelivery} style={{ maxHeight: "25vh" }} />
            </Box>

            <Box pt={4}>
                <Grid
                    container
                    direction="row" >
                    <Grid item xs={12}>
                        <Typography align="center" display="block">
                            <strong>Opa! seu pedido já foi recebido.</strong>
                        </Typography>

                        <Typography align="center">
                            <strong>Só relaxar e aguardar!</strong>
                        </Typography>
                    </Grid>

                    {empresa.tempoMinPreparo || empresa.tempoMaxPreparo != 0 ? (
                        <Grid item xs={12} >
                            <Box display="block" textAlign="center" p={3}>
                                <Alert icon={false} severity="info">
                                    <Box display="block" textAlign="center">
                                        <TempoPreparo />
                                    </Box>
                                </Alert>
                            </Box>
                        </Grid>
                    ) : null}

                    <Grid item xs={12} >

                        <Box display="block" textAlign="center" pt={3}>

                            <Typography variant="caption" display="block" gutterBottom>
                                em caso de dúvidas
                            </Typography>

                            <CustomButton
                                target="_blank"
                                href={`https://wa.me/${foneWhatsapp}?text=${mensagem}`}
                                variant="outlined" color="primary"
                                startIcon={
                                    <WhatsAppIcon />
                                }>
                                Chama no zap
                            </CustomButton>
                        </Box>

                    </Grid>


                </Grid>
            </Box>
        </div>
    )
}

export default FinalizacaoDelivery
