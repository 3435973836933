import React from "react";
import { DialogContent, Dialog, Typography, Button, DialogTitle, Box, CardActionArea, IconButton, useMediaQuery } from "@material-ui/core";
import Utils from '../../../utils/Utils'
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import { getUltimosPedidos, calcularValorTotalPedido } from '../../../utils/PedidoUtil'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useHistory } from "react-router-dom";
import Mesa from "../../../imagens/iconeMesa.svg";
import Sequencial from "../../../imagens/iconeSequencial.svg";
import Comanda from "../../../imagens/iconeComanda.svg";
import DeliveryIcon from "../../../imagens/iconeDelivery.svg";
import CustomizedSteppers from "../../infoPedido/components/ProgressBar";

var moment = require('moment');
moment.updateLocale('en', {
    relativeTime: {
        future: "em %s",
        past: "%s atrás",
        s: 'à poucos segundos',
        ss: '%d segundos',
        m: "um minuto",
        mm: "%d minutos",
        h: "à uma hora",
        hh: "%d horas",
        d: "à um dia",
        dd: "%d dias",
        M: "à um mês",
        MM: "%d meses",
        y: "à um ano",
        yy: "%d anos"
    }
});

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: "10px",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    fontPaperPrint: {
        fontFamily: "'Courier New', Courier, monospace"
    }
}));

export default function Pedidos({ open, handleOpenClosePedidos }) {
    const theme = useTheme()
    const classes = useStyles()
    const history = useHistory()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const ultimosPedidos = getUltimosPedidos(-50)

    const formatDate = (date) => {
        return moment(new Date(date)).format("DD/MM/YYYY - HH:mm")
    }

    const getStep = (pedido) => {
        if (pedido.situacao === "PENDENTE") {
            return 0
        } else if ("RECEBIDO" === pedido.situacao) {
            return 0
        } else if ("CONFIRMADO" === pedido.situacao) {
            return 1
        } else if ("ENVIADO" === pedido.situacao) {
            return 2
        } else if ("ENTREGUE" === pedido.situacao) {
            return 3
        } else {
            return 0
        }
    }

    return (
        <Dialog        
            fullWidth
            open={open}
            maxWidth="sm"
            fullScreen={fullScreen}
            onClose={handleOpenClosePedidos}>

            <DialogTitle >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    Pedidos realizados
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleOpenClosePedidos}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers style={{ background: "#FFFFF0" }}>

                {ultimosPedidos && open && (
                    ultimosPedidos.reverse().map(pedido => (
                        <Card className={classes.card} key={pedido.id}>
                            <CardActionArea onClick={() => history.push(`/infoPedido/${pedido.uuid}`)}>
                                <div style={{ display: "flex", justifyContent: "space-between", margin: "5px" }} >
                                    <Typography variant="body1" color="textSecondary" component="p" className={classes.fontPaperPrint}>
                                        {formatDate(pedido.dataPedido)}
                                    </Typography>

                                    <Typography variant="body1" color="textSecondary" component="p" className={classes.fontPaperPrint}>
                                        <strong> {` ${Utils.formatNumber(calcularValorTotalPedido(pedido))}`} </strong>
                                    </Typography>
                                </div>

                                <div style={{ display: "flex", margin: "5px" }} >
                                    {pedido.identificador.tipo === 'MESA' ? <img style={{ maxHeight: "50px" }} src={Mesa} alt="Imagem mesa" /> : null}
                                    {pedido.identificador.tipo === 'COMANDA' ? <img style={{ maxHeight: "50px" }} src={Comanda} alt="Imagem comanda" /> : null}
                                    {pedido.identificador.tipo === 'SEQUENCIAL' ? <img style={{ maxHeight: "50px" }} src={Sequencial} alt="Imagem sequencial" /> : null}
                                    {pedido.identificador.tipo === 'DELIVERY' ? <img style={{ maxHeight: "50px" }} src={DeliveryIcon} alt="Imagem delivery" /> : null}

                                    {pedido.identificador.tipo === "SEQUENCIAL" ?
                                        <div style={{ margin: "15px" }} className={classes.fontPaperPrint}>
                                            <strong>Sua senha é {pedido.identificadorSecundario}</strong>
                                        </div>
                                        : null}

                                    {pedido.identificador.tipo === "DELIVERY" ?
                                        <React.Fragment>
                                            <div style={{ margin: "15px" }} className={classes.fontPaperPrint}>
                                                <strong>Delivery {pedido.identificadorSecundario}</strong>
                                            </div>
                                        </React.Fragment>
                                        : null}

                                    {(pedido.identificador.tipo !== "DELIVERY" && pedido.identificador.tipo !== "SEQUENCIAL") ?
                                        <div style={{ margin: "15px" }} className={classes.fontPaperPrint}>
                                            <strong>{pedido.identificador.tipo} {pedido.identificador.numero}</strong>
                                        </div>
                                        : null}

                                    <div style={{ margin: "15px" }} className={classes.fontPaperPrint}>
                                        <strong>ID - #{pedido.id}</strong>
                                    </div>

                                </div>

                                <div style={{ display: "flex", margin: "5px" }} >
                                    {pedido.identificador.tipo === "DELIVERY" ?
                                        <div style={{ margin: "15px" }} className={classes.fontPaperPrint}>
                                            <strong>{pedido.tipoDelivery === "ENTREGAR" ? "Para entregar" : null}</strong>
                                            <strong>{pedido.tipoDelivery === "RETIRAR_LOCAL" ? "Para retirar no local" : null}</strong>
                                            <strong>{pedido.tipoDelivery === "CONSUMIR_LOCAL" ? "Para consumir no local" : null}</strong>
                                        </div>
                                        : null}

                                    {pedido.horarioAgendamento ? (
                                        <div style={{ margin: "15px" }} className={classes.fontPaperPrint}>
                                            <strong>Agendado para {formatDate(pedido.horarioAgendamento)}</strong>
                                        </div>
                                    ) : null}
                                </div>

                                <CardContent>
                                    <Box sx={{ width: '100%' }}>
                                        {pedido.situacao === 'CANCELADO' ? (
                                            <Box display="flex" justifyContent="center" gridGap="8px">
                                                <CancelIcon color="error" />
                                                <Typography variant="body1">
                                                    Pedido cancelado
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <CustomizedSteppers pedido={pedido} activeStep={getStep(pedido)} />
                                        )}
                                    </Box>
                                </CardContent>

                            </CardActionArea>

                            {pedido.pagamentos && pedido.pagamentos[0] && pedido.pagamentos[0].formaPagamento.tipo === "PICPAY" ?
                                (
                                    <CardActions >
                                        <Button size="small" fullWidth
                                            onClick={() => { history.push(`/confirmarPagamento/${pedido.uuid}`) }}
                                            endIcon={<OpenInNewIcon />}>Pago com PicPay</Button>
                                    </CardActions>
                                )
                                : null
                            }

                        </Card>

                    ))
                )}

            </DialogContent>

        </Dialog>
    )
}