import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.standard,
        })
    },

    appBarStatic: {
        border: 0,
    },

    appBarFixed: {
        border: 0,
        marginTop: '50px',
    },

    tabs: {
        padding: '5px 0',
        minHeight: '25px',

        '& .MuiTabs-flexContainer': {
            gap: theme.spacing() + 'px',
        },

        '& .MuiTabScrollButton-root.Mui-disabled': {
            opacity: 0.3
        }
    },

    tab: {
        minHeight: '25px',
        borderRadius: theme.shape.borderRadius + 'px',
        border: `1px solid ${theme.palette.primary.main}`,
    }
}))

function GrupoListTabs({ grupoList, onClick }) {
    const classes = useStyles()
    const [value, setValue] = useState(0)

    function handleChange(event, newValue) {
        setValue(newValue)
    }

    useEffect(() => {
        if (!grupoList.length) return

        const list = [...document.getElementsByClassName('group-section')]
        const elementOffsets = list.map(element => ({
            top: element.offsetTop,
            bottom: element.offsetTop + element.offsetHeight - 200
        }))

        let ticking = false

        function handleScroll() {
            if (ticking) return

            ticking = true

            // Check the scroll position only after the current event loop
            requestAnimationFrame(() => {
                const viewportHeight = window.innerHeight
                const viewportTop = window.scrollY
                const viewportBottom = viewportTop + viewportHeight

                let foundVisibleElement = false

                for (let i = 0; i < list.length && !foundVisibleElement; i++) {
                    const { top, bottom } = elementOffsets[i]

                    if (top <= viewportBottom && bottom >= viewportTop) {
                        setValue(i)
                        foundVisibleElement = true
                    }
                }

                ticking = false
            })
        }

        window.addEventListener('scroll', handleScroll)

        // Initial check in case the page is loaded with scroll position
        handleScroll()

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [grupoList])

    return (
        <Tabs
            value={value}
            scrollButtons="on"
            textColor="primary"
            variant="scrollable"
            className={classes.tabs}
            indicatorColor="primary"
            onChange={handleChange}>
            {grupoList.map((grupo, index) => (
                <Tab
                    key={index}
                    color="primary"
                    className={classes.tab}
                    label={grupo.grupoProduto.descricao}
                    onClick={() => onClick(grupo.grupoProduto.id)}
                    wrapped={true} />
            ))}
        </Tabs>
    )
}

function GrupoList({ onClick, grupos, buscandoProdutos }) {
    const classes = useStyles()
    const [grupoList, setGrupoList] = useState([])
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 400 })


    useEffect(() => {
        setGrupoList(grupos)
    }, [grupos])

    return (
        <div style={{ minHeight: '50' }}>
            {buscandoProdutos ? <SkeletonListGroups /> : (
                <AppBar
                    variant='outlined'
                    position={trigger ? 'fixed' : 'static'}
                    color='default'
                    className={clsx(classes.appBar, trigger ? classes.appBarFixed : classes.appBarStatic)} >

                    <GrupoListTabs grupoList={grupoList} onClick={onClick} />
                </AppBar>
            )}
        </div>
    )
}

function SkeletonListGroups() {
    return (
        <Skeleton variant="rect" height={40} />
    )
}

export default GrupoList