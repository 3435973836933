import React, { Component } from "react";
import Chamado from "../../../imagens/chamadogarcon.svg";
import AuthService from "../../../services/AuthService";
import { Box, Typography, Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import CardGenerico from '../../generic/CardGenerico'
import CustomButton from '../../generic/CustomButton'

class ConfirmacaoMesa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enviandoChamado: false,
            mesa: "",
            chamado: this.props.location.state.chamado
        }

        this.retornarParaListagem = this.retornarParaListagem.bind(this)
    }

    condicaoSatisfeitaIdentificador() {
        if (!this.state.mesa) {
            return false;
        }
        if (this.state.mesa.length === 0) {
            return false;
        }
        return true;
    }

    handleChange(event) {
        let mesa = (event.target.value)
        this.setState({ mesa })
    }

    handleChamado(event) {
        let { chamado } = this.state;

        chamado.identificador = "MESA";
        chamado.numeroIdentificador = parseInt(this.state.mesa);

        this.setState({ chamado });

        let service = new AuthService();

        service
            .post("/chamado", chamado)
            .then(retorno => {

                this.setState({ enviando: false })

                if (retorno.id) {
                    this.props.history.push({
                        pathname: `/closeChamado`,
                        search: '',
                        state: { chamado: retorno }
                    })
                }

            }).catch(err => {
                console.log('Erro', err)
                this.setState({ enviando: false })
            })
    }

    retornarParaListagem(event) {
        this.props.history.replace("/");
    }


    render() {
        let { enviandoChamado } = this.state;

        return (
            <React.Fragment>
                <CardGenerico
                    leftButtonClick={event => this.retornarParaListagem(event)}
                    rightButtonClick={event => this.retornarParaListagem(event)}

                    cardMedia={
                        <Grid container spacing={3}
                            direction="row"
                            justify="center"
                            alignItems="center" >
                            <Grid item style={{ textAlign: 'center' }} xs={12} >
                                <Grid width="100%">
                                    <Box style={{ margin: "0 auto" }}>
                                        <img src={Chamado} style={{ maxHeight: "25vh" }} alt="" />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <Box style={{ margin: "0 auto", textAlign: "center" }}>
                                    <Typography color="primary">
                                        <strong>
                                            O garçom está quase vindo, <br /> nos diga o numero da sua mesa!
                                        </strong>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    }

                    cardContent={
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                            justify="center"
                            alignItems="center" >
                            <Grid item xs={12} >
                                <Box p={3}>
                                    <TextField
                                        id="descricao-item"
                                        label="Número da mesa"
                                        name="mesa"
                                        fullWidth
                                        inputProps={{ maxLength: 12 }}
                                        onChange={event => this.handleChange(event)}
                                        variant="outlined"
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    }

                    cardActions={
                        <CustomButton
                            variant="contained" color="secondary" fullWidth
                            disabled={!this.condicaoSatisfeitaIdentificador() || enviandoChamado}
                            onClick={event => this.handleChamado(event)}>
                            {
                                enviandoChamado ? "Enviando..." : "Finalizar pedido"
                            }
                        </CustomButton>
                    }
                />



            </React.Fragment>
        )
    }
}

export default ConfirmacaoMesa;