export const updatePayment = (currentState, action) => {

    return {
        ...currentState,
        payment: {
            ...currentState.payment,
            ...action.data,
        },
    }
}

export const initialStatePayment = () => {

    return {
        open: false,
        pessoa: null,
        tipoDelivery: null,
        formaPagamento: null,
        form: {
            ccv: '',
            number: '',
            cpfCnpj: '',
            expiryDate: '',
            holderName: '',
            creditCardHolderInfo: {
                name: '',
                email: '',
                cpfCnpj: '',
                postalCode: '',
                mobilePhone: '',
                addressNumber: '',
            }
        },
    }
}