import { getPerfile } from "./PedidoUtil";
import moment from "moment";


export default {

    /**
       * 
       * @param {
      * "currency" para formato monetário
      * "percent" para formato percentual;} style 
      * @returns Valor formatado
      */
    optionsCurrency(style = 'currency') {

        const moeda = getMoedaEmpresa()

        let options = {
            currencySymbol: style === 'currency' ? 'R$' : '%',
            decimalPlaces: 2,
        }

        //Japao
        if (moeda === 'IENE') {
            options = {
                currencySymbol: style === 'currency' ? '￥' : '%',
                decimalPlaces: 0,
            }
        }

        return options
    },

    /**
     * 
     * @param {Valor a ser formatado} value 
     * @param {"decimal" para formato de número simples, 
     * "currency" para formato monetário
     * "percent" para formato percentual; 
     * o padrão é "decimal"} style 
     * @returns Valor formatado
     */
    formatNumber(value, style = 'currency') {
        // "￥123,357"=  {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(123356.65)}
        // "R$ 123.356,65" = {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(123356.65)}
        const moeda = getMoedaEmpresa()

        let param = {
            locales: 'pt-BR',
            options: {
                style,
                currency: 'BRL',
                useGrouping: true,
            }
        }

        //Japao
        if (moeda === 'IENE') {
            param = {
                locales: 'ja-JP',
                options: {
                    style,
                    currency: 'JPY',
                    useGrouping: true,
                }
            }
        }

        if (value !== null && value !== undefined) {
            return new Intl.NumberFormat(param.locales, param.options).format(value)
        } else {
            return value
        }
    },
}

export const formatarReal = (valorReal, minFixedChar = 2, maxFixedChar = 2) => {
    if (!valorReal && 0 !== valorReal)
        return " -- "

    if (isNaN(valorReal))
        return ""

    let valor = parseFloat(valorReal)

    if (Number.isInteger(valor)) {
        return valor.toFixed(minFixedChar).replace('.', ',')
    } else {
        return valor.toFixed(maxFixedChar).replace('.', ',')
    }
};

export const trunkStr = (str, size) => {
    if (!str)
        return null

    if (str.length > size) {
        return str.substring(0, size).concat('...')
    } else {
        return str
    }
};

export const diffSecondDate = (dt2, dt1) => {

    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(Math.round(diff));

}


export const createUUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 || 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}


export const hashCode = (str) => {
    return str.split('').reduce((prevHash, currVal) =>
        (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
}


export const converterURLImagemLogo = (urlImagem) => {
    return urlImagem;
}

export const converterURLImagemProduto = (imagem) => {
    return converterImagem(imagem)
}

export const converterImagem = (imagem) => {
    /*
    const baseURL = "https://jura-balde.s3-sa-east-1.amazonaws.com/"
    const convertedURL = 'https://img.imageboss.me/awsjurandi/cdn'

    const urlConverted = convertedURL + urlImagem.substring(baseURL.length - 1, urlImagem.length)*/

    const urlConverted = imagem.url + imagem.publicId
    return urlConverted
}

export const converterImagemWithSize = (imagem, crop = 'cover:center', size = '400x300') => {
    const imageBossUrl = imagem.url.replace('cdn', crop + '/' + size)
    return imageBossUrl + imagem.publicId
}

export const isEmpresaBloqueada = () => {
    let empresa = getPerfile().empresa;
    let hoje = new Date();
    let dataBloqueio = new Date(empresa.dataBloqueio);

    if (!empresa.dataBloqueio) {
        return false
    }

    if (moment(hoje).isAfter(dataBloqueio)) return true;
    else return false;

}

export const getMoedaEmpresa = () => {
    return getPerfile()?.empresa?.moeda;
}

export const convertWhatsAppNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return null;
    }

    let foneWhatsapp = phoneNumber.replace(/\D/g, "")
    foneWhatsapp = "55" + foneWhatsapp.replace(/\D/g, "")

    if (foneWhatsapp.length > 11) {
        return foneWhatsapp
    }

    return null
}

export const onlyDigits = (numbers) => {
    if (!numbers) {
        return "";
    }

    let onlyNumbers = numbers.replace(/\D/g, "")

    return onlyNumbers
}

export const getImagemPrincipal = (produto) => {
    if (produto.imagens && produto.imagens.length > 0) {
        return produto.imagens[0].url
    }

    return `/assets/images/iconsCircles/${produto.grupoProduto.tipoGrupoProduto.imagem}`
}


export const removerPassosPausados = (complementos) => {
    if (!complementos) {
        return []
    }
    complementos.map(complemento => {
        let contAtivos = 0;

        complemento.complementoItens.map(compItem => {
            if (compItem.pausado === false) {
                contAtivos += 1;
            }
        })
        if (contAtivos === 0) {
            let index = complementos.findIndex(el => el.id === complemento.id);
            complementos.splice(index, 1);
        }
    })

    return complementos
}

// função utilizada para converter uma string codificada em base 64 em uma imagem
// normalmente utilizada para exibir o qrcode do PIX
export function encodedBase64ToImageSrc(str) {
    return `data:image/png;base64,${str}`
}