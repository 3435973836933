import React, { useEffect, useState } from "react"
import {
    DialogTitle,
    Typography,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogActions,
    Button
} from "@material-ui/core";
import AuthService from "../../services/AuthService";

const moment = require('moment')

export default function HorarioAbertura(props) {
    const [horarios, setHorarios] = useState([])
    const diasSemana = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado", "Domingo"]
    let hoje = new Date().getDay()

    //if para mudar de domingo = 0 para domingo = 7
    if (hoje === 0) {
        hoje = 7
    }

    useEffect(() => {
        handleListHorarios()
    }, [])

    const handleListHorarios = () => {
        const service = new AuthService()

        service
            .get("/horarioAbertura/list")
            .then(resp => {
                setHorarios(resp)
            }).catch(err => {
                console.log("Erro ao buscar horários de abertuta", err)
            })
    }

    const mostraHorarios = (horario) => {
        const formatTime = (time) => moment(time, "HH:mm:ss").format("HH:mm")

        switch (horario.status) {
            case 0:
                return (
                    <>
                        {formatTime(horario.periodo01Inicio)} - {formatTime(horario.periodo01Fim)} e {formatTime(horario.periodo02Inicio)} - {formatTime(horario.periodo02Fim)}
                    </>
                );
            case 1:
                return `${formatTime(horario.periodo02Inicio)} - ${formatTime(horario.periodo02Fim)}`;
            case 2:
                return `${formatTime(horario.periodo01Inicio)} - ${formatTime(horario.periodo01Fim)}`;
            case 3:
                return ` Fechado`
            default:
                return '';
        }
    }

    return (
        <Dialog open={props.open} onClose={props.handleChangeDialog} fullWidth>
            <DialogTitle>
                <Typography align="center" color="primary">
                    Horários de atendimento
                </Typography>
            </DialogTitle>

            <DialogContent style={{ padding: "0" }}>
                <List dense>
                    {horarios.filter(horario => horario.tipo === "DELIVERY").map(horario => (
                        <ListItem
                            key={horario.diaSemana}
                            disabled={horario.status === 3}
                            selected={hoje === horario.diaSemana}>
                            <ListItemText
                                color="primary"
                                style={{ paddingTop: "0", margin: "0" }}
                                primary={diasSemana[horario.diaSemana - 1]}
                                secondary={mostraHorarios(horario)}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.handleChangeDialog} color="primary" autoFocus>
                    Entendido
                </Button>
            </DialogActions>
        </Dialog>
    )
}