import React, { Component } from "react";
import {
    Box,
    Grid,
    IconButton,
    Typography,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    TextField,
} from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
    calcularFracaoParaComplemento,
    calcularValorTotalItemComComplemento,
    recalcularTotalItem,
    getCart,
    duplicarItemCompleto
} from '../../utils/PedidoUtil';
import Utils, { formatarReal, createUUID, isEmpresaBloqueada } from '../../utils/Utils';
import AuthService from "../../services/AuthService";
import CardGenerico from '../generic/CardGenerico'
import CustomButton from '../generic/CustomButton'
import ImagemProduto from '../generic/ImagemProduto'
import Zoom from '@material-ui/core/Zoom';
import { withSnackbar } from "notistack";

const removerPassosPausados = (complementos) => {
    if (!complementos) {
        return []
    }
    complementos.forEach(complemento => {
        let contAtivos = 0;

        complemento.complementoItens.forEach(compItem => {
            if (compItem.pausado === false) {
                contAtivos += 1;
            }
        })
        if (contAtivos === 0) {
            let index = complementos.findIndex(el => el.id === complemento.id);
            complementos.splice(index, 1);
        }
    })

    return complementos
}

class Steps extends Component {
    constructor(props) {
        super(props);
        /* FAZ ISSO PARA OBTER UMA COPIA COMPLETA DO PRODUTO, pois altera atributos do mesmo */
        const produto = props.produto ? JSON.parse(JSON.stringify(props.produto)) : JSON.parse(JSON.stringify(this.props.location.state.produto))
        //const produto = JSON.parse(JSON.stringify(this.props.location.state.produto));
        const complementos = removerPassosPausados(produto.complementos)

        this.state = {
            produto: produto,
            item: {
                quantidade: 1,
                valorUnitario: produto.preco,
                valorTotal: produto.preco,
                nomeCliente: '',
                descricao: '',
                valorDesconto: 0,
                produto: produto
            },
            tamanhoSelecionado: null,
            tela: {
                passoAtual: (complementos && complementos.length > 0 ? 0 : -1),
                complementos: complementos
            },
            animation: true
        }
        this.avancarProximoPasso = this.avancarProximoPasso.bind(this)
        this.addSubitem = this.addSubitem.bind(this)
        this.onBackStep = this.onBackStep.bind(this)
        this.confirmarAdicaoItem = this.confirmarAdicaoItem.bind(this)
        this.handleVerificarTamanho = this.handleVerificarTamanho.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.reloadAnimation = this.reloadAnimation.bind(this)
        this.addAndRemove = this.addAndRemove.bind(this)
        this.buttonDisabledClick = this.buttonDisabledClick.bind(this)
        this.voltarAoPedido = this.voltarAoPedido.bind(this)
    }

    reloadAnimation() {
        this.setState({ animation: false })
        setTimeout(() => { this.setState({ animation: true }) }, 200);
    }


    handleVerificarTamanho(item) {
        this.setState({ tamanhoSelecionado: item })

        const { complementos } = this.state.tela
        const complementoAtual = complementos[this.state.tela.passoAtual]
        complementoAtual.complementoItens.map(item => (
            item.quantidade = null
        ))

        item.quantidade = 1
    }


    voltarAoPedido() {
        this.props.history.replace({ pathname: "/" })
    }

    addAndRemove(option) {
        const { item } = this.state;

        if (option === true) {
            item.quantidade = item.quantidade + 1;
        } else {
            if (item.quantidade > 1) {
                item.quantidade = item.quantidade - 1;
            }
        }
        recalcularTotalItem(item)
        this.setState({ item: item })
    }

    addAndRemoveQtdePrincipal(sinal) {
        const { item } = this.state;

        if (sinal === "+") {
            item.quantidadePrincipal = (item.quantidadePrincipal || 1) + 1;
        } else if (sinal === "-") {
            if (item.quantidadePrincipal > 1) {
                item.quantidadePrincipal = item.quantidadePrincipal - 1;
            }
        }
        recalcularTotalItem(item)
        this.setState({ item: item })
    }

    avancarProximoPasso() {
        let { tela } = this.state

        let qtdePassos = tela.complementos.length
        tela.passoAtual += 1

        /* CHEGOU NO ULTIMO PASSO */
        if (qtdePassos < tela.passoAtual + 1) {
            tela.passoAtual = -1
        }

        this.setState({ tela })
        this.reloadAnimation()
    }

    confirmarAdicaoItem() {
        let { tela } = this.state
        let { item } = this.state

        if (isEmpresaBloqueada()) {
            this.props.history.push("/errorSistemaBloqueado")
            return;
        }

        let subItens = []
        tela.complementos.map(complemento => {

            /* FRACAO É VALIDA PRA CALCULAR PIZZAS PROPORCIONAIS */
            let fracao = calcularFracaoParaComplemento(complemento)

            complemento.complementoItens.map(complementoItem => {
                if (complementoItem.quantidade && complementoItem.quantidade > 0) {

                    let qtdeFracionada = complementoItem.quantidade * fracao
                    let qtdeFracionadaTrunk = parseFloat(qtdeFracionada.toFixed(3))

                    let subItem = {
                        uuid: createUUID(),
                        quantidade: qtdeFracionadaTrunk,
                        valorDesconto: 0,
                        valorUnitario: complementoItem.valor,
                        valorTotal: qtdeFracionada * complementoItem.valor,
                        descricao: complementoItem.descricao,
                        codigoExterno: complementoItem.codigoExterno,
                        descricaoPasso: complemento.descricao,
                        complementoItem: { id: complementoItem.id }
                    }
                    subItens.push(subItem)
                }
            })
        })
        item.subItens = subItens

        this.setState({ item })

        //PASSO = -1 é PASSO FINAL
        if (tela.passoAtual === -1) {
            if (item.quantidadePrincipal && item.quantidadePrincipal > 1) {
                let qtdePrincipal = item.quantidadePrincipal
                for (var i = 0; i < qtdePrincipal; i++) {
                    let itemDuplicado = duplicarItemCompleto(item)
                    getCart().adicionarItemPedidoCorrente(itemDuplicado)
                }
            } else {
                getCart().adicionarItemPedidoCorrente(item)
            }
            this.props.history.push({ pathname: "/" })
        }

    }

    permiteAdicionar(complementoAtual) {
        if (!complementoAtual) {
            return true
        }
        let max = complementoAtual.max
        let qtdeSelecionada = 0
        complementoAtual.complementoItens.map(complementoItem => {
            if (complementoItem.quantidade)
                qtdeSelecionada += complementoItem.quantidade
        })
        return max > qtdeSelecionada
    }

    condicaoSatisfeitaComplemento(complementoAtual) {

        if (complementoAtual === undefined)
            return true

        //se nao tiver complementos, retorna true
        if (!complementoAtual.complementoItens)
            return true

        if (complementoAtual.complementoItens.length === 0)
            return true

        let max = complementoAtual.max
        let min = complementoAtual.min
        let obrigatorio = complementoAtual.obrigatorio

        let qtdeSelecionada = 0
        complementoAtual.complementoItens.map(complementoItem => {
            if (complementoItem.quantidade)
                qtdeSelecionada += complementoItem.quantidade
        })
        if (obrigatorio) {
            return max >= qtdeSelecionada && qtdeSelecionada >= min && qtdeSelecionada > 0
        } else {
            return max >= qtdeSelecionada && (qtdeSelecionada === 0 || min <= qtdeSelecionada)
        }
    }

    addSubitem(itemComplemento) {
        let { tela } = this.state
        const { complementos } = tela
        const complementoAtual = complementos[tela.passoAtual]

        if (!this.permiteAdicionar(complementoAtual)) {
            return
        }

        if (itemComplemento.quantidade) {
            itemComplemento.quantidade += 1
        } else {
            itemComplemento.quantidade = 1
        }
        this.setState({ tela })
    }

    removeSubItem(itemComplemento) {
        if (itemComplemento.quantidade === 0) {
            itemComplemento.quantidade = 0
        } else {
            itemComplemento.quantidade -= 1
        }
        let { tela } = this.state
        this.setState({ tela })
    }

    onBackStep() {
        let { tela } = this.state
        if (tela.passoAtual > 0) {
            tela.passoAtual -= 1
            this.reloadAnimation()
        } else {
            if (tela.complementos.length > 0 && tela.passoAtual === -1) {
                tela.passoAtual = tela.complementos.length - 1
                this.reloadAnimation()
            } else {
                window.history.back()
            }
        }
        this.setState({ tela })
    }

    buttonDisabledClick() {
        const { tela } = this.state
        const { complementos } = tela
        const complementoAtual = complementos[tela.passoAtual]
        const condicaoSatisfeita = this.condicaoSatisfeitaComplemento(complementoAtual)

        if (!condicaoSatisfeita) {
            let msg = ""
            if (complementoAtual.tipoComplemento === 'TAMANHO') {
                msg = "Selecione um tamanho"
            } else {
                msg = `A quantidade mínima é ${complementoAtual.min}`
            }
            this.props.enqueueSnackbar(msg,
                {
                    preventDuplicate: true,
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
        }
    }


    handleChange = event => {
        const { item } = this.state;
        item[event.target.name] = event.target.value
        this.setState({ item })
    };

    render() {
        const { item, produto, tela, animation } = this.state
        const complementos = tela.complementos
        const complementoAtual = complementos[tela.passoAtual]

        const condicaoSatisfeita = this.condicaoSatisfeitaComplemento(complementoAtual)
        const permiteAdicionar = this.permiteAdicionar(complementoAtual)

        const ultimoPasso = tela.passoAtual === -1

        let tipoTela = "PASSO_FINAL"
        if (complementoAtual) {
            if (complementoAtual.tipoComplemento === 'TAMANHO') {
                tipoTela = "TAMANHO"
            }
            if (complementoAtual.tipoComplemento !== 'TAMANHO') {
                tipoTela = "PASSOS"
            }
        }

        let styles = {
            total: {
                fontFamily: "Bebas Neue",
                fontSize: "18px",
                fontWeight: "500",
                textTransform: "uppercase",
                marginLeft: "15px"
            }
        }

        return (
            <CardGenerico
                leftButtonClick={this.onBackStep}
                rightButtonClick={this.voltarAoPedido}
                cardMedia={
                    <ImagemProduto produto={produto} />
                }
                cardContent={
                    <React.Fragment>
                        {tipoTela === 'TAMANHO' ? (
                            <Zoom in={animation}>
                                <div>
                                    <Box pt={2} pb={2}>  </Box>
                                    <Grid container direction="row" justify="center" alignItems="center" key={item.id}>
                                        <Typography style={{ fontFamily: "Bebas Neue" }} color="primary" variant="h6" component="h3">
                                            Escolha o tamanho
                                        </Typography>
                                        <Grid item style={{ textAlign: 'center' }} xs={12}>
                                            {complementoAtual.complementoItens.map(item => (
                                                item.pausado ? null
                                                    : (
                                                        <FormControl component="fieldset" key={item.id}>
                                                            <RadioGroup
                                                                value={this.state.tamanhoSelecionado || ''}
                                                                onChange={() => { this.handleVerificarTamanho(item) }}
                                                                name="tamanhoSelecionado">
                                                                <FormControlLabel
                                                                    value={item}
                                                                    control={<Radio color="primary" />}
                                                                    label={
                                                                        <React.Fragment>
                                                                            <Typography style={{ fontFamily: "Bebas Neue" }} color="primary"> {item.descricao} </Typography>
                                                                            <Typography color='secondary' style={{ fontFamily: "Bebas Neue" }}> {Utils.formatNumber(item.valor)} </Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                    labelPlacement="bottom"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )
                                            ))}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Zoom>
                        ) : null}

                        {tipoTela === 'PASSOS' ? (
                            <Zoom in={animation}>
                                <div>

                                    <Grid container direction="row">
                                        <Grid item style={{ textAlign: 'center' }} xs={12}>
                                            <Typography style={{ fontFamily: "Bebas Neue" }} color="primary" variant="h6" component="h3" >
                                                {complementoAtual.descricao}
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ textAlign: 'center' }} xs={12}>
                                            <Typography variant="caption" display="block" color="primary"
                                                style={{ opacity: (condicaoSatisfeita ? "0.4" : "1") }} >
                                                {complementoAtual.obrigatorio ? '* ' : null} Mínimo {complementoAtual.min} e máximo {complementoAtual.max}
                                            </Typography>

                                        </Grid>

                                        <Grid item xs={12}>
                                            <div >
                                                {complementoAtual.complementoItens.map(item => (
                                                    item.pausado ? null : (
                                                        <Box key={item.id} display="flex" alignItems="center">
                                                            <Box flexGrow={1} >
                                                                <strong style={{ textTransform: 'uppercase', fontSize: "80%" }}> {item.descricao} </strong>

                                                                {item.descricaoComplementar ? (
                                                                    <Typography variant="caption" display="block" color="textSecondary" gutterBottom>
                                                                        {item.descricaoComplementar}
                                                                    </Typography>
                                                                ) : null}

                                                            </Box>

                                                            <Box display="flex" alignItems="center">
                                                                <Box>
                                                                    <Typography variant="caption" >{formatarReal(item.valor)}</Typography>
                                                                </Box>

                                                                <Box style={{ whiteSpace: "nowrap" }} >
                                                                    {item.quantidade ?
                                                                        <IconButton onClick={() => this.removeSubItem(item)} >
                                                                            <RemoveIcon />
                                                                        </IconButton>
                                                                        : null}
                                                                    <strong>
                                                                        {item.quantidade === 0 ? null : item.quantidade}
                                                                    </strong>
                                                                    <IconButton onClick={() => this.addSubitem(item)} disabled={!permiteAdicionar}>
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                ))}
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </Zoom>
                        ) : null}

                        {tipoTela === 'PASSO_FINAL' ? (

                            <React.Fragment>
                                <Zoom in={animation}>
                                    <div>
                                        <Box p={2}>

                                            <Box display="flex" alignItems="center" pt={2} pb={2}>
                                                <Box flexGrow={1}>
                                                    <strong style={styles.quantidade}> quantidade </strong>
                                                </Box>

                                                {!produto.complementos.length ? (
                                                    <Box>
                                                        <IconButton disabled={item.quantidade < 2} onClick={() => this.addAndRemove(false)}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        <strong style={{ padding: "0 10px 0 10px" }}>{item.quantidade}</strong>
                                                        <IconButton onClick={() => this.addAndRemove(true)} >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <IconButton disabled={!item.quantidadePrincipal || item.quantidadePrincipal < 2} onClick={() => this.addAndRemoveQtdePrincipal("-")}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        <strong style={{ padding: "0 10px 0 10px" }}>{item.quantidadePrincipal || 1}</strong>
                                                        <IconButton onClick={() => this.addAndRemoveQtdePrincipal("+")} >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                )}

                                            </Box>

                                        </Box>

                                        <Box p={2} >

                                            <TextField
                                                label="Alguma observação?"
                                                placeholder="Ex: tirar cebola, maionese à parte.."
                                                multiline
                                                rows="1"
                                                name="observacao"
                                                value={item.observacao}
                                                onChange={this.handleChange}
                                                fullWidth variant="outlined" />
                                        </Box>

                                    </div>
                                </Zoom>
                            </React.Fragment>

                        ) : null}

                    </React.Fragment>

                }

                cardActions={
                    <Box display="block" textAlign="center">
                        <Typography style={styles.total} color="primary">
                            {`TOTAL ${Utils.formatNumber(calcularValorTotalItemComComplemento(item, complementos))}`}
                        </Typography>

                        <div onClick={this.buttonDisabledClick}>
                            {ultimoPasso ?
                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    disabled={!condicaoSatisfeita}
                                    onClick={this.confirmarAdicaoItem}
                                    endIcon={
                                        <DoneIcon />
                                    }>
                                    Adicionar produto
                                </CustomButton>
                                :
                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    disabled={!condicaoSatisfeita}
                                    endIcon={
                                        <KeyboardArrowRightIcon />
                                    }
                                    onClick={this.avancarProximoPasso}>
                                    Próximo passo
                                </CustomButton>
                            }
                        </div>

                    </Box>
                }
            />
        );
    }
}

export default withSnackbar(Steps)



