import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import SocketIO from '.'
import AuthService from '../../services/AuthService'
import { getPessoa } from '../../utils/PedidoUtil'

export default function SocketContext() {
    const dispatch = useDispatch()
    const [websocket, setWebsocket] = useState(null)

    const URL_SOCKET = process.env.REACT_APP_SOCKET_URL
    const envRoom = process.env.REACT_APP_SOCKET_ROOM
    const websocketAuthToken = process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN

    const profile = AuthService.getProfile()
    const pessoa = getPessoa()

    // TODO: juntar nome da pessoa + cpfCnpj (hoje não salva o cpfCnpj na pessoa)

    // auth - token uuid configurado no servidor socket e na variavel de ambiente
    const query = 'room=' + profile?.empresa?.uuid + '_QRPEDIR' + envRoom + '&auth=' + websocketAuthToken + '&empresa=' + profile.empresa.nome + '&username=' + pessoa.nome

    useEffect(() => {
        const profile = AuthService.getProfile()

        if (!!profile && !!profile.empresa && !!profile.empresa.uuid && profile.empresa.uuid.length > 3 && !!websocket) {
            dispatch({ type: 'UPDATE_SOCKET', websocket: websocket })
            console.log("Socket criado e atualizado")
        } else if (websocket) {
            console.log("Nenhum usuário logado ou empresa encontrada - não é possível definir a sala para o socket")
        }
        // eslint-disable-next-line
    }, [websocket])

    const addEvents = (socket) => {
        const profile = AuthService.getProfile()

        socket.room = profile.empresa.uuid + process.env.REACT_APP_SOCKET_ROOM

        setWebsocket(socket)

        // recebe um update de socket de outros usuarios socket
        socket.on('Update', (event) => {
            onMessageReceive(event)
        })

        // recebe um update do socket do servidor
        socket.on('reciveSocketMessage', (event) => {
            onMessageReceive(event)
        })
    }

    const onMessageReceive = (event) => {
        const { type, data } = event

        console.log('socket event recebido - type ->', type)

        if (type === 'UPDATE_ONLINE_PAYMENT') {
            dispatch({ type, data: { onlinePayment: JSON.parse(data) } })
        }
    }

    return <SocketIO addEvents={addEvents} url={URL_SOCKET} query={query} />
}