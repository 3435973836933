import React, { Component, Fragment } from "react";
import Robo from "../../../imagens/RoboGarcon.svg"
import AuthService from "../../../services/AuthService";
import { getCart, saveCart, saveUltimoPedido, clearCart, saveNumeroIdentificador, getNumeroIdentificador, getPerfile } from '../../../utils/PedidoUtil';
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import CardGenerico from '../../generic/CardGenerico'
import CustomButton from '../../generic/CustomButton'
import * as Pixel from '../../../utils/FacebookPixelUtil';
import { withSnackbar } from "notistack";


class FormNome extends Component {
  state = {
    identificadorSecundario: getNumeroIdentificador() || '',
    Errors: null,
    errorMsg: null,
    enviandoPedido: false
  }

  handleChange(event) {
    let identificadorSecundario = (event.target.value)
    this.setState({ identificadorSecundario })
  }

  actionMsgClick = (snackMsg) => (
    <Fragment>
      <Button onClick={() => {
        this.props.closeSnackbar(snackMsg);
        this.props.history.replace("/pedido");
      }
      } color="inherit">
        Voltar ao carrinho
      </Button>
    </Fragment>
  );




  handleSaveCart() {
    const { identificadorSecundario } = this.state
    let identificador = getPerfile().empresa.tipoIdentificadorSecundario

    this.setState({ enviandoPedido: true })

    let service = new AuthService();
    service
      .get(`/identificador/get/${identificador}/${identificadorSecundario}`)
      .then(retorno => {
        if (retorno.ativo === true) {

          console.log('identificador válido')
          let cart = getCart()
          cart.pedido.identificadorSecundario = this.state.identificadorSecundario
          saveNumeroIdentificador(this.state.identificadorSecundario)
          cart.pedido.identificador = JSON.parse(localStorage.getItem('_profile'))
          cart.pedido.dataPendente = new Date();
          saveCart(cart)

          if (cart.pedido.itens && cart.pedido.itens.length === 0) {
            this.setState({ enviandoPedido: false });
            alert("Ops, seu pedido não tem nenhum item. Vamos te direcionar para o cardápio")
            this.props.history.replace("/");
            return
          }

          let service = new AuthService();
          service
            .post("/pedido", cart.pedido)
            .then(retorno => {
              if (Pixel.isPixelOn()) {
                let pixelObj = {
                  value: cart.valorTotalPedido,
                  content_name: cart.pedido.nomeCliente,
                  currency: 'BRL',

                }
                Pixel.trackEvent('Purchase', pixelObj)
              }
              saveUltimoPedido(retorno)
              clearCart()
              this.props.history.replace("/closePedido");

            }).catch(err => {
              console.log(err)

              if (err.response.status === 417) {
                err.response.json().then(elem => {
                  this.props.enqueueSnackbar(elem.errorMessage, {
                    variant: "error",
                    action: this.actionMsgClick
                  });
                });
              } else if (err.response.status === 406) {
                this.props.history.replace("/errorSistemaBloqueado");
              } else {
                this.props.history.replace("/errors");
              }

            }).finally(() => {
              this.setState({ enviandoPedido: false })
            })


        } else {
          console.log("identificador inválido")
          this.setState({ Errors: true, errorMsg: `A ${identificador === 'MESA' ? 'mesa' : 'comanda'} ${identificadorSecundario} está inativa. Tente outra.` });
          this.setState({ enviandoPedido: false })

        }
      }).catch(err => {
        this.setState({ Errors: true, errorMsg: `Ops, não encontramos. Tem certeza que é esse mesmo?` });
        console.log('identificador não existe')
        this.setState({ enviandoPedido: false })
      })
  }

  condicaoSatisfeitaIdentificador() {

    if (!this.state.identificadorSecundario || this.state.identificadorSecundario.length === 0) {
      return false;
    } else {
      if (this.state.enviandoPedido) {
        return false;
      } else {
        return true;
      }
    }
  }

  retornarParaListagem() {
    this.props.history.replace("/");
  }

  render() {
    const { identificadorSecundario, Errors, enviandoPedido, errorMsg } = this.state;
    return (
      <React.Fragment>
        <CardGenerico
          leftButtonClick={() => window.history.back()}
          rightButtonClick={event => this.retornarParaListagem(event)}
          cardMedia={
            <Box style={{ margin: "0 auto" }}>
              <img src={Robo} style={{ maxHeight: "25vh" }} />
            </Box>
          }
          cardContent={
            <Grid container spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center">

              <Grid item style={{ textAlign: 'center' }} xs={12}>
                {getPerfile().empresa.tipoIdentificadorSecundario === 'MESA' ?
                  <Typography color="primary">
                    <strong>
                      Para qual MESA devo <br /> mandar seu pedido?
                    </strong>
                  </Typography>
                  :
                  <Typography color="primary">
                    <strong>
                      Poderia me dizer qual o <br /> número de sua COMANDA?
                    </strong>
                  </Typography>}
              </Grid>

              <Grid item xs={12} >
                <Box style={{ margin: "0 auto", textAlign: "center" }} pr={2} pl={2}>
                  <TextField
                    variant="outlined"
                    id="descricao-item"
                    placeholder={'Número' + (getPerfile().empresa.tipoIdentificadorSecundario === 'MESA' ? ' da mesa' : ' da comanda')}
                    name="identificadorSecundario"
                    value={identificadorSecundario}
                    autoFocus={!identificadorSecundario}
                    fullWidth
                    onChange={event => this.handleChange(event)}
                    error={Errors}
                    helperText={Errors === true ? (errorMsg ? errorMsg : "Ops, não encontramos. Tem certeza que é esse mesmo?") : null}
                  />

                </Box>
              </Grid>

            </Grid>
          }

          cardActions={
            <CustomButton
              variant="contained" color="secondary"
              fullWidth
              disabled={!this.condicaoSatisfeitaIdentificador()}
              onClick={event => this.handleSaveCart(event)}>
              {enviandoPedido ? 'Confirmando...' : 'Confirmar'}
            </CustomButton>
          }
        />

      </React.Fragment>
    )
  }
}
export default withSnackbar(withRouter(FormNome));