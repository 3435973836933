export const updateOnlinePayment = (currentState, action) => {

    return {
        ...currentState,
        onlinePayment: {
            ...currentState.onlinePayment,
            ...action.data,
        },
    }
}

export const initialStateOnlinePayment = () => {

    return {
        onlinePayment: null
    }
}