import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CloseIcon from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import AuthService from "../../../services/AuthService"
import Menu from "./menu"
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import { getIdsPedidosStorage, getPerfile, subscribeUltimosPedidos, ultimoPedidoIsUnfinished } from '../../../utils/PedidoUtil'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { useHistory } from "react-router-dom"
import BuscarProdutos from '../components/BuscarProduto'
import PulseBadge from '../../generic/PulseBadge'
import { useTheme } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  btnMenuGrupos: {
    position: "fixed",
    top: "10px",
    zIndex: "100",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    opacity: "0.8",
  },
  titleGrupo: {
    textTransform: "uppercase"
  },
  title: {
    flexGrow: 1,
    fontFamily: "Bebas Neue",
    textTransform: 'uppercase',

  }
}))

export default function MenuGrupos(props) {
  const theme = useTheme()
  const classes = useStyles()
  const service = new AuthService()

  const [open, setOpen] = useState(false)
  const [grupos, setGrupos] = useState([])
  const [openMenu, setOpenMenu] = useState(false)

  const profile = getPerfile() //recebe o perfil da empresa
  const history = useHistory() //utilizado para chamar novas páginas
  const chamarGarcom = profile ? profile.empresa.chamarGarcom : false
  const isDelivery = profile.tipo === "DELIVERY"
  const descriptionElementRef = useRef(null)
  const appBarRef = useRef(null)
  const toolbarRef = useRef(null)


  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // chama a página de chamado de garçom
  const handleOpenChamado = () => {
    history.push("/chamarGarcom")
  }

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
      setGrupos(props.grupos)
    }
  }, [open])

  useEffect(() => {
    let idsPedidos = getIdsPedidosStorage()
    if (!!idsPedidos) {
      service.get(`/pedido/idList/${idsPedidos}`)
        .then(resp => {
          if (!!resp) {
            subscribeUltimosPedidos(resp)
          }
          console.log("resp", resp)
        })
    }
  }, [])

  useEffect(() => {
    const headerCoverImg = document.querySelector('.header-cover img')

    function onScroll() {
      if (!appBarRef.current || !toolbarRef.current) return


      if (window.scrollY > 125 && appBarRef.current) {
        appBarRef.current.style.opacity = 1;
        appBarRef.current.style.backgroundColor = theme.palette.primary.main;
        toolbarRef.current.style.color = 'inherit';
      } else {
        appBarRef.current.style.opacity = '0.7';
        appBarRef.current.style.backgroundColor = '#000';
        toolbarRef.current.style.color = '#fff';
      }

      if (headerCoverImg) {
        headerCoverImg.style.filter = `blur(${(this.scrollY / 30)}px)`
      }
    }

    window.addEventListener('scroll', onScroll, {
      capture: true,
      passive: true,
    })

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [appBarRef, toolbarRef, theme])

  return (
    <>
      <AppBar style={{ backgroundColor: 'transparent' }}>
        <Box ref={appBarRef} style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, backgroundColor: '#000', opacity: 0.7, transition: '0.2s' }} />
        <Toolbar  ref={toolbarRef} style={{color: '#fff'}} variant="dense">
          {ultimoPedidoIsUnfinished() ? (
            <PulseBadge
              style={{ marginBottom: '12px' }}
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot">
              <Box style={{ marginBottom: '-10px', marginRight: '-10px' }}>
                <IconButton
                  aria-label="Abrir menu"
                  edge="start"
                  onClick={() => (setOpenMenu(!openMenu))}
                  color="inherit">
                  <MenuIcon />
                </IconButton>

                <Menu
                  openMenu={openMenu}
                  toogleMenu={() => (setOpenMenu(!openMenu))}
                  openCategorias={handleClickOpen} />
              </Box>
            </PulseBadge>
          ) : (
            <Box style={{ marginBottom: '2px', marginRight: '-10px' }}>
              <IconButton
                aria-label="Abrir menu"
                edge="start"
                onClick={() => (setOpenMenu(!openMenu))}
                color="inherit">
                <MenuIcon />
              </IconButton>

              <Menu
                openMenu={openMenu}
                toogleMenu={() => (setOpenMenu(!openMenu))}
                openCategorias={handleClickOpen} />
            </Box>
          )}

          <Box flexGrow={1} marginLeft={'5px'}>
            <Typography className={classes.title}  >
              {AuthService.getProfile().empresa.nome}
            </Typography>
          </Box>

          <BuscarProdutos />

          {/* Verifica se é delivery e se a função de chamar garçom está ativa para exibir o botão de chamar botão */}
          {!isDelivery && chamarGarcom ? (
            <Box display="flex" justifyContent="center" >
              <Button color="inherit" onClick={handleOpenChamado} startIcon={<NotificationsActiveIcon fontSize="small" />}>
                Garçom
              </Button>
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        style={{
          maxHeight: "calc(100% - 60px)"
        }}>
        <DialogTitle id="scroll-dialog-title">Categorias</DialogTitle>

        <DialogContent dividers={true} style={{ padding: "0" }}>
          <List>
            {grupos.map(grupo => (
              <ListItem button key={grupo.grupoProduto.id}
                className={classes.titleGrupo}
                onClick={() => { setTimeout(() => (props.handleGrupoClick(grupo.grupoProduto.id)), 200); handleClose() }}>
                <ListItemIcon>
                  <img src={`/assets/images/icons/${grupo.grupoProduto.tipoGrupoProduto.imagem}`} style={{ maxWidth: "48px", maxHeight: "48px" }} />
                </ListItemIcon>
                <ListItemText primary={grupo.grupoProduto.descricao} />
              </ListItem>
            ))}
          </List>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" startIcon={<CloseIcon />}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}