import React, { Fragment, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, Typography } from "@material-ui/core";
import Nome from "./components/Nome";
import Endereco from "./components/Endereco";
import FormaPagamento from "./components/FormaPagamento";
import moment from 'moment';
import {
    getCart, saveCart,
    saveUltimoPedido, clearCart,
    getNomeCliente, savePessoa,
    getPessoa, calcularValorTotalPedido,
    calcularValorTotalItensPedido,
    getPerfile
} from "../../utils/PedidoUtil";
import { formatarReal, onlyDigits } from "../../utils/Utils";
import AuthService from "../../services/AuthService";
import { useSnackbar } from "notistack";
import CardGenerico from '../generic/CardGenerico'
import CustomButton from '../generic/CustomButton'
import Alert from '@material-ui/lab/Alert';
import PicPay from "../../utils/PicPay";
import DadosPicPay from "./components/DadosPicPay"
import * as Pixel from '../../utils/FacebookPixelUtil';
import { excluirItemPedidoPeloUUID } from '../../utils/PedidoUtil'
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DialogOnlinePayment } from "./components/DialogOnlinePayment";
import { useDispatch } from "react-redux";
import { ReviewOrderDialog } from "../Payment/ReviewOrderDialog";

export default function Pessoa() {
    let empresa = getPerfile().empresa
    let pessoaJaSalva = getPessoa()
    const history = useHistory();
    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [passoAtual, setPassoAtual] = useState(0)
    const [enviandoPedido, setEnviandoPedido] = useState(false)
    const [tipoDelivery, setTipoDelivery] = useState(null) /* ENTREGAR, RETIRAR_LOCAL */
    const [valorTaxaEntrega, setValorTaxaEntrega] = useState(0)
    const [pessoa, setPessoa] = useState(
        pessoaJaSalva || {
            nome: getNomeCliente() || "",
            telefone: "",
            cep: "",
            endereco: "",
            numero: "",
            bairro: "",
            bairroEntrega: null,
            complemento: "",
            municipio: empresa.municipio,
            estado: empresa.estado,
        })
    const [formaPagamento, setFormaPagamento] = useState({})
    const [observacao, setObservacao] = useState("")
    const [mensagemErro, setMensagemErro] = useState("")
    const [openPicPayDialog, setOpenPicPayDialog] = useState(false)
    const [selectedDay, setSelectedDay] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [openReviewDialog, setOpenReviewDialog] = useState(false)
    const [configuracaoAgendamento, setConfiguracaoAgendamento] = useState(null)
    const [openDialogItemExcluido, setOpenDialogItemExcluido] = useState(false)
    const [openDialogAgendamentoProibido, setOpenDialogAgendamentoProibido] = useState(false)
    const [selectedTimestampSchedule, setSelectedTimestampSchedule] = useState(null)
    const [pagamentoOnlineForm, setPagamentoOnlineForm] = useState(null)
    const [troco, setTroco] = useState()
    const [errosAsaas, setErrosAsaas] = useState([])
    const statusLoja = AuthService._statusLoja()

    const isPixOnline = formaPagamento?.tipoPagamento === 'online' && formaPagamento.tipo === 'PIX'
    const isCreditCardOnline = formaPagamento?.tipoPagamento === 'online' && formaPagamento.tipo === 'CREDIT_CARD'

    useEffect(() => {
        getConfigsAgendamento()
    }, [])

    function getConfigsAgendamento() {
        var service = new AuthService();

        service
            .get("/configurarAgendamento/configuracaoAtual")
            .then(resp => {
                setConfiguracaoAgendamento(resp)
            }).catch(err => {
                console.error(err)
            })
    }

    function limparMensagemErro() {
        setMensagemErro("")
    }


    const handleChangeDiaEHorario = (dia, horario) => {
        let selectedTimestampScheduleTemp = null

        if (dia !== null && horario) {
            var hoje = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });

            var diaAgendamento = moment(hoje).utc().add(dia, 'days') /*Adicionado .utc para padronizar o horário */
            var horarioArray = horario.split(":");
            diaAgendamento.hour(horarioArray[0]);
            diaAgendamento.minute(horarioArray[1]);
            selectedTimestampScheduleTemp = diaAgendamento;
        }

        setSelectedDay(dia)
        setSelectedTime(horario)
        setSelectedTimestampSchedule(selectedTimestampScheduleTemp)
    }

    const proximoPasso = () => {
        /* 0 - NOME E TELEFONE , 1 - ENDERECO, 2 - FORMA PAGAMENTO*/

        limparMensagemErro()
        let novoPasso = null

        if ((tipoDelivery === "RETIRAR_LOCAL" || tipoDelivery === "CONSUMIR_LOCAL") && passoAtual === 0) {
            novoPasso = passoAtual + 2;
        } else {
            novoPasso = passoAtual + 1;
        }

        setPassoAtual(novoPasso)
    }

    const passoAnterior = () => {

        limparMensagemErro()
        let novoPasso = null

        if ((tipoDelivery === "RETIRAR_LOCAL" || tipoDelivery === "CONSUMIR_LOCAL") && passoAtual === 2) {
            novoPasso = passoAtual - 2;
        } else {
            novoPasso = passoAtual - 1;
        }

        setPassoAtual(novoPasso)
    }

    function setMunicipioPessoa(municipio) {
        let novaPessoa = Object.assign({}, pessoa)
        novaPessoa.municipio = municipio
        novaPessoa.estado = municipio.estado;
        novaPessoa.bairroEntrega = null

        setPessoa(novaPessoa)
    }

    function setBairroPessoa(bairro) {
        let novaPessoa = Object.assign({}, pessoa)

        novaPessoa.bairro = bairro

        setPessoa(novaPessoa)
        savePessoa(novaPessoa)
    }

    function setBairroEntregaPessoa(bairro) {

        let novaPessoa = Object.assign({}, pessoa)

        novaPessoa.bairroEntrega = bairro

        setPessoa(novaPessoa)
        savePessoa(pessoa)
    }

    const handleChange = (event, values) => {

        if (event.target.name === "tipoDelivery") {
            setTipoDelivery(event.target.value)
            limparMensagemErro()

        } else if (event.target.name === "municipio") {

            let municipioString = event.target.value
            let municipio = JSON.parse(municipioString)
            setMunicipioPessoa(municipio)

        } else if (event.target.name === "bairro") {

            let bairroString = event.target.value
            setBairroPessoa(bairroString)

        } else if (event.target.name === "bairroEntrega") {
            let bairroId = event.target.value
            let bairro = values.find(bairro => bairro.id === bairroId)
            setBairroEntregaPessoa(bairro)
        } else {
            let newPessoa = Object.assign({}, pessoa)
            newPessoa[event.target.name] = event.target.value;
            setPessoa(newPessoa)
        }

    }

    const handleReturn = () => {

        if (passoAtual > 0) {
            passoAnterior()
        } else {
            window.history.back()
        }
    }

    const getMsgErroPassoAtual = () => {

        let empresa = getPerfile().empresa

        var telefoneNumbersOnly = onlyDigits(pessoa.telefone);
        if (passoAtual === 0) {
            //DADOS INICIAL PESSOA
            if (!pessoa.nome) return "O nome deve ser informado."

            if (!pessoa.telefone) return "O telefone deve ser informado."

            if (telefoneNumbersOnly.length < 10 || telefoneNumbersOnly.length > 11) return "Telefone inválido."

            if (!tipoDelivery) return "Informe o tipo de entrega."
        }


        if (passoAtual === 1) {
            //DADOS ENDERECO
            if (!pessoa.endereco) {
                return "O endereço deve ser informado."
            }

            if (pessoa.endereco.length < 3) {
                return "O endereço deve conter no minimo 3 caracteres."
            }

            if (!pessoa.numero) {
                return "O número deve ser informado."
            }

            let isEmptyBairro = false

            if (empresa.configuracaoEntrega === 'BAIRRO') {
                if (!pessoa.bairroEntrega) {
                    isEmptyBairro = true
                } else if (!pessoa.bairroEntrega.municipio) {
                    isEmptyBairro = true
                } else if (!pessoa.bairroEntrega.municipio.id === pessoa.municipio.id) {
                    isEmptyBairro = true
                }
            } else {
                if (!pessoa.bairro) {
                    isEmptyBairro = true
                }
            }

            if (isEmptyBairro) {
                return "O bairro deve ser informado."
            }
        }

        if (passoAtual === 2) {
            //FORMA PAGAMENTO
            if (!formaPagamento.id) {
                return "Selecione a forma de pagamento."
            }
        }

        return null
    }

    //Utilizada na SnackBar para gerar o botão de voltar ao carrinho
    const action = () => (
        <Fragment>
            <Button onClick={() => { history.replace("/pedido"); }} variant="contained">
                Voltar ao carrinho
            </Button>
        </Fragment>
    );

    function doPagamentoOnline(pagamento) {
        return new Promise((resolve, reject) => {
            const service = new AuthService()
            const formCopy = { ...pagamentoOnlineForm }

            if (formaPagamento.tipo === 'PIX') {
                const cart = getCart()

                formCopy.billingType = formaPagamento.tipo
                formCopy.holderName = pessoa.nome
                formCopy.cpfCnpj = formCopy.cpfCnpj || ''
                formCopy.value = calcularValorTotalPedido(cart.pedido) + (valorTaxaEntrega || 0)
                formCopy.idEmpresa = empresa.id
                formCopy.idFormaPagamento = formaPagamento.id
                formCopy.dueDate = new Date()
            }

            service.post('/pagamento/asaas/criar-cobranca', formCopy).then((resp) => {
                pagamento.status = resp.status
                pagamento.referenceId = resp.id
                pagamento.paymentUrl = resp.invoiceUrl

                resolve(pagamento)
            }).catch((err) => {
                if (err?.response) {
                    err.response.json().then((resp) => {
                        const { errors = [] } = resp
                        setErrosAsaas(errors)
                    })
                }

                reject(err)
            })
        })
    }

    async function handleSaveCart(options) {

        let { pagamentosExternos } = options != null ? options : {}

        setEnviandoPedido(true)

        let service = new AuthService();
        var cart = getCart()

        let pessoaCopy = Object.assign({}, pessoa)
        pessoaCopy.cep = pessoa.cep.replace("-", "");

        cart.pedido.identificador = JSON.parse(localStorage.getItem('_profile'))
        cart.pedido.dataPendente = new Date();

        if (selectedTimestampSchedule) {
            cart.pedido.horarioAgendamento = selectedTimestampSchedule;
        }

        saveCart(cart)

        service
            .post("/pessoa", pessoaCopy)
            .then(async pessoaRetorno => {

                cart.pedido.tipoDelivery = tipoDelivery

                cart.pedido.pessoa = pessoaRetorno;
                cart.pedido.nomeCliente = pessoaRetorno.nome;
                cart.pedido.descricao = formaPagamento.descricao + " - OBS: " + observacao;
                cart.pedido.troco = troco

                if (tipoDelivery === "ENTREGAR") {
                    let empresa = getPerfile().empresa

                    cart.pedido.valorTaxaEntrega = valorTaxaEntrega
                    cart.pedido.municipio = pessoa.municipio;

                    if (empresa.configuracaoEntrega === "BAIRRO") {
                        //TEORICAMENTE SEMPRE DEVE TER O BAIRRO DE ENTREGA AQUI
                        if (pessoa.bairroEntrega) {
                            cart.pedido.bairro = pessoa.bairroEntrega.bairro
                            cart.pedido.bairroEntrega = pessoa.bairroEntrega
                        }
                    } else {
                        cart.pedido.bairro = pessoa.bairro
                    }

                    cart.pedido.endereco = pessoa.endereco;
                    cart.pedido.numero = pessoa.numero;
                    cart.pedido.complemento = pessoa.complemento;
                    cart.pedido.cep = pessoa.cep;
                }

                /* CALCULA TOTAL DO PEDIDO (COM VALOR DE ENTREGA ) */
                let totalPedido = calcularValorTotalPedido(cart.pedido)
                cart.pedido.totalPedido = totalPedido;

                //pagamentosExternos -> (no caso do pagamento com picpay)
                //Se não tiver, cria um pagamento com a forma selecionada
                if (pagamentosExternos && pagamentosExternos.length > 0) {
                    cart.pedido.pagamentos = pagamentosExternos;
                } else if (formaPagamento?.tipoPagamento === 'online') {
                    const pagamento = await doPagamentoOnline({ formaPagamento, valor: totalPedido })

                    cart.pedido.situacao = 'AGUARDANDO_CONFIRMACAO_PGTO'
                    cart.pedido.pagamentos = [pagamento]
                } else {
                    let pagamento = { valor: totalPedido, formaPagamento: formaPagamento }
                    cart.pedido.pagamentos = [pagamento]
                }

                if (pessoaRetorno)
                    savePessoa(pessoaRetorno)

                saveCart(cart)

                if (cart.pedido.itens && cart.pedido.itens.length === 0) {
                    setEnviandoPedido(false)
                    alert("Ops, seu pedido não tem nenhum item. Vamos te direcionar para o cardápio")
                    history.replace("/");
                    return
                }
                service
                    .post("/pedido", cart.pedido)
                    .then(retorno => {

                        if (Pixel.isPixelOn()) {
                            let pixelObj = {
                                municipio: pessoa.municipio.nome,
                                value: cart.valorTotalPedido,
                                taxaEntrega: retorno.valorTaxaEntrega,
                                content_name: pessoa.nome,
                                currency: 'BRL'
                            }
                            Pixel.trackEvent('Purchase', pixelObj)
                        }

                        saveUltimoPedido(retorno)
                        clearCart()
                        setEnviandoPedido(false)
                        console.log("Pedido salvo. Situação do retorno: ", retorno.situacao)

                        if (retorno.situacao === 'AGUARDANDO_CONFIRMACAO_PGTO' && formaPagamento?.tipoPagamento === 'online') {
                            history.push(`/confirmar-pagamento-online/${retorno.uuid}`)
                        } else if (retorno.situacao === "AGUARDANDO_CONFIRMACAO_PGTO") {
                            //faz solicitação de pagamento para o picpay 
                            let pagamentoPicPay = retorno.pagamentos[0]
                            if (pagamentoPicPay) {
                                history.push(`/confirmarPagamento/${retorno.id}`);
                            }
                        } else {
                            history.replace("/closePedido");
                        }

                    }).catch(err => {
                        console.error(err)
                        if (err?.response?.status === 405) {
                            err.response.json().then(elem => {
                                console.log(elem);
                                enqueueSnackbar(elem.errorMessage, { variant: "error", action: action(), });
                            });
                        } else if (err?.response?.status === 406) {
                            history.replace("/errorSistemaBloqueado");
                        } else if (err.response.status === 417) {
                            err.response.json().then(elem => {
                                enqueueSnackbar(elem.errorMessage, {
                                    variant: "error",
                                    action: actionMsgClick
                                });
                            });
                        } else {
                            history.replace("/errors");
                        }

                        setEnviandoPedido(false)
                    })

            }).catch(err => {
                console.error(err)
                enqueueSnackbar('Houve um erro ao finalizar o pedido', { variant: "error" });
                setEnviandoPedido(false)
            })

    }

    const actionMsgClick = (snackMsg) => (
        <Fragment>
            <Button onClick={() => {
                closeSnackbar(snackMsg);
                history.replace("/pedido");
            }} color="inherit">
                Voltar ao carrinho
            </Button>
        </Fragment>
    )

    const handleConfirmaDadosPicPay = (newPessoa) => {
        limparMensagemErro()

        setPessoa(newPessoa)
        setOpenPicPayDialog(false)

        const PICPAY_TOKEN_FORMA_PGTO = formaPagamento.picpayToken

        let referenceIdGerado = "PED_QRPEDIR_" + parseInt(Math.random() * 10 ** 12)

        const URL_ORIGEM = document.location.origin

        let cart = getCart()
        let totalPedido = calcularValorTotalPedido(cart.pedido) + valorTaxaEntrega

        let payment = {
            "referenceId": referenceIdGerado,
            "callbackUrl": "https://api.qrpedir.com/pagamento/callbackPicPay",
            "returnUrl": `${URL_ORIGEM}/confirmarPagamento/${referenceIdGerado}`,
            "value": totalPedido,
            "buyer": {
                "firstName": newPessoa.nome,
                "lastName": newPessoa.sobrenome,
                "document": newPessoa.cpfCnpj,
                "email": newPessoa.email,
                "phone": "+55" + newPessoa.telefone
            }
        }

        let apiPicPay = new PicPay({ picpayToken: PICPAY_TOKEN_FORMA_PGTO })
        apiPicPay
            .doPayment(payment)
            .then(retornoPagamento => {

                //cria forma pagamento picpay
                let pagamento = {
                    valor: totalPedido,
                    formaPagamento: formaPagamento,
                    referenceId: retornoPagamento.referenceId,
                    paymentUrl: retornoPagamento.paymentUrl,
                }
                console.log("Pagamento criado a partir do retorno do picpay", pagamento)

                cart.pedido.pagamentos = null
                cart.pedido.situacao = "AGUARDANDO_CONFIRMACAO_PGTO"

                saveCart(cart)
                handleSaveCart({ paymentRedirectUrl: retornoPagamento.paymentUrl, pagamentosExternos: [pagamento] })
            }).catch(err => {

                if ([401, 422, 500].includes(err.response.status)) {
                    //ERROS DO PICPAY

                    err.response.json().then(elem => {
                        console.error("Picpay - Solicitação de pagamento recusada", elem)
                        setMensagemErro(`Falha pagamento PicPay: ${elem.message}`)
                    });

                } else {
                    console.error("Picpay - Erro na solicitação de pagamento", err)
                    setMensagemErro(`Falha pagamento PicPay: ${err.response.statusText}`)
                }
            })

    }


    const handleSubmitVerificandoPagamento = () => {

        if (formaPagamento && formaPagamento.tipo === "PICPAY" && passoAtual === 2) {
            setOpenPicPayDialog(true);
        } else if (isCreditCardOnline && passoAtual === 2 && !pagamentoOnlineForm) {
            dispatch({ type: 'UPDATE_PAYMENT', data: { tipoDelivery, formaPagamento, pessoa, open: true } })
        } else if (isPixOnline && passoAtual === 2 && !pagamentoOnlineForm) {
            handleSubmit()
        } else {
            handleSubmit()
        }
    }

    const handleSubmit = () => {

        console.log('handleSubmit', passoAtual)

        if (passoAtual === 0) {
            //DADOS INICIAIS
            setMensagemErro("")

            /* Valida agendamento */

            let obrigatorioAgendamento = configuracaoAgendamento?.situacao === "A"
            let agendamentoSelecionado = !!selectedTimestampSchedule

            console.log("configuracaoAgendamento", configuracaoAgendamento)
            console.log("obrigatorioAgendamento", obrigatorioAgendamento)
            console.log("agendamentoSelecionado", agendamentoSelecionado)

            if (obrigatorioAgendamento && !agendamentoSelecionado) {
                setMensagemErro("É necessário informar uma data de agendamento")
                return
            }


            let valido = isPedidoMinimValido()

            if (valido) {
                proximoPasso();
            }

        } else if (passoAtual === 1) {
            let empresa = getPerfile().empresa

            //VALIDAR NUMERO DO ENDERECO
            const regexNumero = /[^A-Za-z0-9]+/;
            let numeroEnderecoInformado = pessoa.numero
            let numeroOK = regexNumero.exec(numeroEnderecoInformado)
            if (numeroOK) {
                //console.log("NUMERO INVALIDO")
                setMensagemErro("Campo 'Número' está inválido. Informe apenas números ou SN (para sem número)")
                return;
            }

            //DADOS ENDERECO
            setMensagemErro("")

            setEnviandoPedido("Aguarde...")


            if (empresa.configuracaoEntrega === "BAIRRO") {

                if (!pessoa.bairroEntrega || pessoa.bairroEntrega.municipio.id !== pessoa.municipio.id) {
                    setMensagemErro("Nenhum bairro selecionado para entrega")
                } else {
                    setValorTaxaEntrega(pessoa.bairroEntrega.valor)
                    setEnviandoPedido("")
                    proximoPasso();
                }

            } else {

                buscarTaxaEntregaMaisAdequada(pessoa)
                    .then(retorno => {
                        setEnviandoPedido("")

                        let newValorTaxaEntrega = 0;

                        if (retorno.configuracaoTaxa) {
                            newValorTaxaEntrega = retorno.configuracaoTaxa.valor;
                            proximoPasso();
                        } else {

                            if (retorno.tipoErro === "DISTANCIA_EXCEDIDA") {
                                let newMensagemErro = "Que pena! Seu endereço fica muito longe. \
                            Se preferir, você pode retirar no local (voltando ao passo anterior). \
                            Ou então, experimente informar seu cep"
                                setMensagemErro(newMensagemErro)
                            }

                            if (retorno.tipoErro === "DISTANCIA_EXCEDIDA") {
                                let newMensagemErro = "Que pena! Seu endereço fica muito longe. Se preferir, você pode retirar no local (voltando ao passo anterior)"
                                setMensagemErro(newMensagemErro)
                            }

                            if (retorno.tipoErro === "ERRO_BUSCAR_DISTANCIA") {
                                let newMensagemErro = "Infelizmente não encontramos seu endereço. Tente alterá-lo (quem sabe incluir o cep)"
                                setMensagemErro(newMensagemErro)
                            }
                        }

                        setValorTaxaEntrega(newValorTaxaEntrega)
                    })
                    .catch(err => {
                        console.error("Falha ao buscar taxa...", err)
                        setEnviandoPedido("")

                        if (err.message) {
                            setMensagemErro(err.message)
                        } else {
                            err.response.json().then(elem => {
                                console.error(elem);
                                setMensagemErro(elem.errorMessage)
                            });
                        }
                    });
            }
        } else if (passoAtual === 2) {
            setOpenReviewDialog(true)
        }

    }

    const handleFinalizarPedido = () => {

        if (formaPagamento.tipoPagamento === "online" && formaPagamento.tipo === 'PIX') {
            if (!pagamentoOnlineForm?.cpfCnpj) {
                setErrosAsaas([{ description: 'CpfCnpj obrigatorio' }])
                return
            }

            pagamentoOnlineForm.cpfCnpj = pagamentoOnlineForm.cpfCnpj.replace(/\D/g, '') || ''
            if (pagamentoOnlineForm.cpfCnpj.length !== 11 && pagamentoOnlineForm.cpfCnpj.length !== 14) {
                setErrosAsaas([{ description: 'CpfCnpj inválido' }])
                return
            }
        }

        if (handleDeleteItemCart()) {
            setEnviandoPedido(false)
            return
        }

        setEnviandoPedido(true)

        let service = new AuthService()
        service.getEmpresaStatusLoja(retorno => {

            let statusAbertura = retorno.statusAbertura

            if ("FECHADO" === statusAbertura) {
                //SE ESTIVER FECHADO

                if (!selectedTimestampSchedule) {
                    setEnviandoPedido(false)
                    setOpenDialogAgendamentoProibido(true)
                    return
                }
            }

            handleSaveCart()
        })

    }

    const handleChoosePayment = (newFormaPagamento) => {
        setErrosAsaas([])
        setPagamentoOnlineForm(null)
        setFormaPagamento(newFormaPagamento)
    }

    const handleChangeObs = (event) => {
        setObservacao(event.target.value)
    }

    const retornarParaListagem = () => {
        history.replace("/");
    }

    const retornarParaCarrinho = () => {
        history.replace("/pedido");
    }

    function buscarTaxaEntregaMaisAdequada(cliente) {

        return new Promise((resolve, reject) => {

            if (!cliente.municipio) {
                reject(new Error("É necessário informar o munícipio do cliente"))
            }

            if (!cliente.estado) {
                reject(new Error("É necessário informar o estado (UF) do cliente"))
            }

            //EX DESTINO: :R. Curitiba, 1810 - Centro, Francisco Beltrão - PR, 85601-630, Brasil
            let destino = `${cliente.endereco}, ${cliente.numero} - ${cliente.bairro}, ${cliente.municipio.nome} - ${cliente.estado.sigla}`

            if (cliente.cep) {
                let digitosCep = cliente.cep.replace(/\D/g, '');
                if (digitosCep.length === 8) {
                    destino = `${destino}, ${digitosCep}`
                }
            }

            let service = new AuthService()

            service
                .get(`/configuracaoTaxa/buscarTaxaPeloEndereco/${destino}`)
                .then(resp => {
                    resolve(resp)
                }).catch(err => {
                    console.error("Falha ao consultar endereco", err)
                    reject(err)
                })

        });

    }

    const isPedidoMinimValido = () => {
        let empresa = getPerfile().empresa;
        let cart = getCart();
        let valorTotal = calcularValorTotalItensPedido(cart.pedido);

        if (tipoDelivery === "ENTREGAR" && empresa.valorMinimo > valorTotal) {
            setMensagemErro(`Que pena. O valor mínimo para entrega é R$ ${formatarReal(empresa.valorMinimo)}. Que tal adicionar algo mais?`)
            return false
        } else {
            return true
        }
    }

    const handleSaveTroco = (newTroco) => {
        setTroco(newTroco)
    }

    const handleDeleteItemCart = () => {
        let cart = getCart()
        let itemInvalido = false

        cart.pedido.itens.map(item => {
            let dateItem = item.addAt
            let today = new Date().getTime()

            if (dateItem) {
                var diffMs = (today - dateItem);
                var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

                if (diffMins >= 120) {
                    excluirItemPedidoPeloUUID(item.uuid)
                    itemInvalido = true
                }
            }
        })

        setOpenDialogItemExcluido(itemInvalido)

        console.log(itemInvalido)
        return itemInvalido
    }

    function handleOpenPaymentForm() {
        setErrosAsaas([])

        if (!pagamentoOnlineForm) {
            dispatch({ type: 'UPDATE_PAYMENT', data: { tipoDelivery, formaPagamento, pessoa, open: true } })
            return
        }

        dispatch({ type: 'UPDATE_PAYMENT', data: { tipoDelivery, formaPagamento, pessoa, form: pagamentoOnlineForm, open: true } })
    }

    function handleChangeCpfCpnj(event) {
        const formCopy = { ...pagamentoOnlineForm }

        formCopy.cpfCnpj = event.target.value

        setPagamentoOnlineForm(formCopy)
    }

    return (
        <>
            <Fade in={true}>
                <CardGenerico
                    leftButtonClick={handleReturn}
                    rightButtonClick={event => retornarParaListagem(event)}
                    cardContent={
                        <>
                            {passoAtual === 0 && (
                                <Nome pessoa={pessoa}
                                    handleChange={handleChange}
                                    diaAgendamento={selectedDay}
                                    horaAgendamento={selectedTime}
                                    tipoDelivery={tipoDelivery}
                                    configuracaoAgendamento={configuracaoAgendamento}
                                    selectedTimestampSchedule={selectedTimestampSchedule}
                                    handleChangeDiaEHorario={handleChangeDiaEHorario} />
                            )}

                            {passoAtual === 1 && (
                                <Endereco
                                    empresa={empresa}
                                    pessoa={pessoa}
                                    handleChange={handleChange}
                                    setMunicipioPessoa={setMunicipioPessoa}
                                    setBairroEntregaPessoa={setBairroEntregaPessoa} />
                            )}

                            {passoAtual === 2 && (
                                <>
                                    <FormaPagamento
                                        handleSaveTroco={handleSaveTroco}
                                        handleChangeObs={handleChangeObs}
                                        handleChoosePayment={handleChoosePayment}
                                        handleOpenPaymentForm={handleOpenPaymentForm}
                                        handleChangeCpfCpnj={handleChangeCpfCpnj}
                                        cpfCnpj={pagamentoOnlineForm?.cpfCnpj || ''}
                                        errosAsaas={errosAsaas}
                                        tipoDelivery={tipoDelivery}
                                        formaPagamento={formaPagamento}
                                        valorTaxaEntrega={valorTaxaEntrega} />
                                    <DadosPicPay
                                        pessoa={pessoa}
                                        open={openPicPayDialog}
                                        handleClose={() => setOpenPicPayDialog(false)}
                                        confirmarDados={handleConfirmaDadosPicPay} />
                                    <DialogOnlinePayment
                                        valorTaxaEntrega={valorTaxaEntrega}
                                        onConfirm={(formPagamento) => setPagamentoOnlineForm(formPagamento)} />
                                </>
                            )}
                        </>
                    }

                    cardActions={
                        <>
                            <Grid
                                container
                                direction="row">

                                {mensagemErro && (
                                    <Grid item xs={12} >
                                        <Alert severity="error">
                                            <Typography variant="caption">{mensagemErro}</Typography>
                                        </Alert>
                                        <Typography color="error" variant="caption" display="block"></Typography>
                                    </Grid>
                                )}

                                {(configuracaoAgendamento?.situacao === "A" && (!!!selectedTimestampSchedule)) && (
                                    <Grid item xs={12} >
                                        <Alert severity="error">
                                            <Typography variant="caption">É necessario selecionar a data de agendamento</Typography>
                                        </Alert>
                                        <Typography color="error" variant="caption" display="block"></Typography>
                                    </Grid>
                                )}

                                {statusLoja === "FECHADO" ? (
                                    <Grid item xs={12} >
                                        <CustomButton color="secondary"
                                            disabled={!!getMsgErroPassoAtual() || enviandoPedido}
                                            variant="contained" fullWidth
                                            onClick={handleSubmitVerificandoPagamento} >
                                            {enviandoPedido ? "Aguarde..." : "Próximo"}
                                        </CustomButton>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} >
                                        <CustomButton color="secondary"
                                            disabled={!!getMsgErroPassoAtual() || enviandoPedido || (configuracaoAgendamento?.situacao === "A" && (!!!selectedTimestampSchedule))}
                                            variant="contained" fullWidth
                                            onClick={handleSubmitVerificandoPagamento} >
                                            {enviandoPedido ? "Aguarde..." : (passoAtual === 2 ? "Finalizar" : "Próximo")}
                                        </CustomButton>
                                    </Grid>
                                )}
                            </Grid>

                        </>
                    }

                />
            </Fade>

            <ReviewOrderDialog
                open={openReviewDialog}
                pessoa={pessoa}
                troco={troco}
                formaPagamento={formaPagamento}
                valorTaxaEntrega={valorTaxaEntrega}
                onConfirm={handleFinalizarPedido}
                onClose={() => setOpenReviewDialog(false)} />

            <Dialog open={openDialogItemExcluido}>
                <DialogTitle>Seu pedido expirou</DialogTitle>
                <DialogContent>
                    Seus itens ficaram por duas horas no carrinho,
                    por isso eles expiraram e foram deletados.
                    Vamos te redirecionar para que você escolha novamente.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => retornarParaCarrinho()}>Cardápio</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogAgendamentoProibido}>
                <DialogTitle>Horário não permitido</DialogTitle>
                <DialogContent>
                    Você está tentando fazer um pedido, mas a loja está fechada.
                    Por favor, tente novamente mais tarde!
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => retornarParaListagem()}>Voltar</Button>
                </DialogActions>
            </Dialog>

        </>
    )

}