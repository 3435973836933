import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Avatar, withTheme } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import Logo from "../../imagens/LogoQrpedir.svg";
import Nuvem from "../../imagens/Nuvem.png";
import AuthService from "../../services/AuthService";
import Cart from "../Cart";
import { getPerfile } from '../../utils/PedidoUtil';
import Box from '@material-ui/core/Box';
import { converterURLImagemLogo } from '../../utils/Utils';
import * as pedidoUtil from '../../utils/PedidoUtil';
import * as Pixel from '../../utils/FacebookPixelUtil';
import Typography from '@material-ui/core/Typography';
import MenuGrupos from './MenuGrupos';
import PainelPromocao from "./components/PainelPromocao";
import HeaderCardapio from './components/headerCardapio'
import MensagemDelivery from "./components/MensagemDelivery";
import CardapioList from "./components/CardapioList"
import { Provider } from 'react-redux'
import store from "../../store"
import GrupoList from "./components/GrupoList";
import StatusLoja from "../Info/StatusLoja";
import { StarRating } from "../avaliacaoEmpresa/StarRating";
import { HeaderCover } from "./components/HeaderCover";

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            grupos: [],
            buscandoProdutos: false
        }

        this.carregarCardapio = this.carregarCardapio.bind(this)
        this.handleClickOnCart = this.handleClickOnCart.bind(this)
        this.handleOpenChamado = this.handleOpenChamado.bind(this)
        this.posicionaListaGrupo = this.posicionaListaGrupo.bind(this)
    }


    componentDidMount() {

        if (Pixel.isPixelOn()) {
            Pixel.init();
            Pixel.trackPageView();
        }

        if (pedidoUtil.isNecessarioRenovarQRCode()) {
            pedidoUtil.limparDadosNovaSessao()
            console.log("Redirecionando para a leitura do QR")
            this.props.history.replace("/readQRcode");
            return;
        } else {
            this.carregarCardapio()
        }

        window.onbeforeunload = function () {
            return 'Deseja sair do app?';
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    posicionaListaGrupo(idGrupo) {
        let element = document.getElementById(`section_grupo_produto_${idGrupo}`)

        if (element) {
            const yOffset = -100;
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({ top: y })
        } else {
            console.log("Elemento não encontrado para posicionar")
        }

    }

    carregarCardapio() {
        this.setState({ buscandoProdutos: true })
        let service = new AuthService();

        service
            .get("/grupoProduto/listComProdutos")
            .then(retorno => {
                this.setState({ grupos: retorno });
                this.setState({ buscandoProdutos: false })
            })
            .catch(err => {
                this.setState({ buscandoProdutos: false })
                this.props.history.replace("/errors");
            });
    }

    handleClickOnCart() {
        this.props.history.push("/pedido");
    }

    handleOpenChamado() {
        this.props.history.push("/chamarGarcom");
    }

    render() {
        const styles = {
            nomeEmpresa: {
                flex: 1,
                paddingTop: '16px',
                fontFamily: "Bebas Neue",
                paddingRight: '116px',
            },
            logoLarge: {
                width: "100px",
                height: "100px",
            },
            containerCardapio: {
                paddingLeft: "0",
                paddingRight: "0",
            },
        }

        const profile = getPerfile()
        const { grupos, buscandoProdutos } = this.state
        let isDelivery = profile && profile.tipo === "DELIVERY"

        let formatoLogo = profile?.empresa?.formatoLogo === "square" ? "rounded" : "circular"

        // se a empresa não estiver ativa redireciona para tela de sistema bloqueado
        if (!profile?.empresa?.ativo) {
            this.props.history.replace("/errorSistemaBloqueado");
        }

        return (
            <Provider store={store}>
                <HeaderCover empresa={profile?.empresa} />

                <Container
                    maxWidth="md"
                    component="main"
                    style={styles.containerCardapio}>

                    <CssBaseline />

                    <Box position="relative" display="flex" gap="16px" minHeight={80} px={2}>
                        <StarRating />
                        <Typography
                            variant="h5"
                            component="h1"
                            color="primary"
                            style={styles.nomeEmpresa}>
                            {AuthService.getProfile().empresa.nome}
                        </Typography>
                        <Box position="absolute" top="-50px" right="16px">
                            {profile.empresa.urlLogo ? (
                                <Avatar alt={`Logo da empresa ${profile.empresa.nome}`} variant={formatoLogo} src={converterURLImagemLogo(profile.empresa.urlLogo)} style={styles.logoLarge} />
                            ) : (
                                <img alt="Logo" src={Logo} style={{ 'width': "45%", 'height': '60%' }} />
                            )}
                            <StatusLoja />
                        </Box>
                    </Box>

                    {isDelivery && <HeaderCardapio />}

                    <MensagemDelivery />

                    <Box justifyContent="center" p={1}>
                        <GrupoList
                            grupos={grupos}
                            buscandoProdutos={buscandoProdutos}
                            onClick={this.posicionaListaGrupo} />
                    </Box>

                    <Box justifyContent="center">
                        <PainelPromocao />
                    </Box>

                    <MenuGrupos grupos={grupos} handleGrupoClick={this.posicionaListaGrupo} />

                    <CardapioList grupos={grupos} buscandoProdutos={buscandoProdutos} />

                    <Cart handleClickOnCart={this.handleClickOnCart} />

                    <Box>
                        <img alt='nuvem' src={Nuvem} style={{ width: "100%", marginBottom: '-15%' }} />
                    </Box>
                </Container>

            </Provider>

        );
    }
}



export default withTheme(withRouter(Products));
