import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, Fab, TextField, Typography, useTheme, Zoom } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import AuthService from '../../services/AuthService'
import { isErrorAsaas, isPaidAsaas, isRefundedAsaas, STATUS_ASAAS } from '../../utils/Asaas'
import Utils from '../../utils/Utils'
import { IconByPaymentType } from '../generic/IconByPaymentType'
import { InfoTile } from '../generic/InfoTile'
import SocketContext from '../socket/SocketContext'

import CallMadeIcon from '@material-ui/icons/CallMade'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import { ErrosAsaas } from '../Pessoa/components/FormaPagamento'

const HeaderBlock = function HeaderBlock({ payment, errors }) {
    const SIZE = 125
    const theme = useTheme()
    const hasError = errors.length > 0
    const isPaid = isPaidAsaas(payment?.status)
    const isRefunded = isRefundedAsaas(payment?.status)
    const isError = isErrorAsaas(payment?.status)

    let severity = 'info'
    if (hasError || isError) {
        severity = 'error'
    } else if (isPaid) {
        severity = 'success'
    } else if (isRefunded) {
        severity = 'warning'
    }

    let text = 'Aguardando'
    let color = theme.palette.secondary.main
    let icon = <CircularProgress color="secondary" size={SIZE} thickness={2} />

    if (severity === 'success') {
        text = 'Pagamento efetuado'
        color = theme.palette.success.main
        icon = <CheckCircleIcon style={{ color, fontSize: SIZE + "px" }} />
    } else if (severity === 'warning') {
        text = 'Atenção'
        color = theme.palette.warning.main
        icon = <MoneyOffIcon style={{ color, fontSize: SIZE + "px" }} />
    } else if (severity === 'error') {
        text = 'Ocorreu um erro'
        color = theme.palette.error.main
        icon = <ErrorOutlineIcon style={{ color, fontSize: SIZE + "px" }} />
    }

    return (
        <>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gridGap="8px">
                {icon}
                <Typography variant="h5" align="center" style={{ color, fontWeight: 500 }}>
                    {text}
                </Typography>
            </Box>

            {hasError && (
                <ErrosAsaas erros={errors} />
            )}

            {payment && !!STATUS_ASAAS[payment.status] && !hasError && !isPaid && (
                <Box mt={2}>
                    <Alert severity={severity}>
                        <AlertTitle>Status</AlertTitle>
                        {STATUS_ASAAS[payment.status]}
                        {!!payment.log && (
                            <Typography color="error">{payment.log}</Typography>
                        )}
                    </Alert>
                </Box>
            )}
        </>
    )
}

function Pix({ data }) {
    const { enqueueSnackbar } = useSnackbar()

    function handleCopiar() {
        navigator.clipboard.writeText(data.payload)
        enqueueSnackbar('Código copiado com sucesso', { variant: 'success' })
    }

    return (
        <>
            <Box display="flex" alignItems="center" flexDirection="column" gridGap="8px">
                <img
                    alt="QrCode Pix"
                    style={{ maxWidth: '225px' }}
                    src={`data:image/jpeg;charset=utf-8;base64,${data.encodedImage}`} />
                <TextField
                    disabled
                    fullWidth
                    variant="outlined"
                    defaultValue={data.payload} />
                <Button
                    onClick={handleCopiar}
                    startIcon={<FilterNoneIcon />}>
                    Copiar código
                </Button>
            </Box>
        </>
    )
}

const RedirectAlert = memo(function RedirectAlert({ uuid }) {
    const history = useHistory()
    const [counter, setCounter] = useState(5)

    useEffect(() => {
        if (counter === 0) {
            history.replace(`/infoPedido/${uuid}`)
            return
        }

        setTimeout(() => setCounter(counter - 1), 1000)
    }, [history, counter])

    return (
        <Box display="flex" alignItems="center" flexDirection="column" pt={1}>
            <Typography variant="body1">
                Você será redirecionado para o pedido em
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
                {counter} segundos
            </Typography>
        </Box>
    )
})

function FabButton({ appearIn, onClick, children }) {
    return (
        <Zoom unmountOnExit in={appearIn}>
            <Box
                left={0}
                right={0}
                display="flex"
                justifyContent="center"
                position="fixed"
                bottom="16px">
                <Fab
                    color="secondary"
                    variant="extended"
                    onClick={onClick}>
                    <CallMadeIcon />
                    {children}
                </Fab>
            </Box>
        </Zoom>
    )
}

export function ConfirmarPagamentoOnline({ match }) {
    const { uuid } = match.params
    const theme = useTheme()
    const history = useHistory()
    const [pix, setPix] = useState()
    const [order, setOrder] = useState()
    const [errors, setErrors] = useState([])
    const { onlinePayment } = useSelector(state => state.onlinePayment)

    const payment = order?.pagamentos[0]
    const isPaid = isPaidAsaas(payment?.status)
    const isRefunded = isRefundedAsaas(payment?.status)
    const isError = isErrorAsaas(payment?.status)

    useEffect(() => {
        const service = new AuthService()

        service.get('/pedido/uuid/' + uuid).then(resp => {
            setOrder(resp)
        }).catch(console.log)
    }, [uuid])

    useEffect(() => {
        if (payment?.status === 'PAYMENT_CREATED') {
            if (payment.formaPagamento.tipo !== 'PIX') return

            const service = new AuthService()

            service.post('/pagamento/asaas/gerar-pix-qrcode/' + payment.id).then((resp) => {
                setPix(resp)
            }).catch((err) => {
                console.log(err)

                if (err?.response) {
                    err.response.json().then((resp) => {
                        const { errors = [] } = resp
                        setErrors(errors)
                    })
                } else {
                    setErrors([{
                        description: 'Houve um erro ao gerar as informações do PIX',
                    }])
                }
            })
        }
    }, [order])

    useEffect(() => {
        if (onlinePayment && order) {
            const orderCopy = { ...order }

            if (orderCopy.pagamentos[0].referenceId === onlinePayment.payment.id) {
                orderCopy.pagamentos[0].status = onlinePayment.event
                setPix(null)
            }

            setOrder(orderCopy)
        }
    }, [onlinePayment])

    function formatDate(date) {
        return moment(new Date(date)).format('DD/MM/YYYY HH:mm:ss')
    }

    function toMainPage() {
        history.replace('/')
    }

    function toOrderPage() {
        history.replace(`/infoPedido/${order.uuid}`)
    }

    return (
        <Dialog
            fullScreen
            open={true}
            style={{ maxWidth: theme.breakpoints.values.sm, margin: 'auto' }}>

            <DialogContent style={{ paddingBottom: 80 }}>
                {pix ? (
                    <Pix data={pix} />
                ) : (
                    <HeaderBlock
                        errors={errors}
                        payment={payment} />
                )}

                {isPaid && <RedirectAlert uuid={order.uuid} />}

                {!!payment && (
                    <>
                        <Box display="flex" alignItems="center" gridGap="8px" mt={2}>
                            <IconByPaymentType type={payment.formaPagamento.tipo} />
                            <Typography style={{ flex: 1 }} variant="body2">
                                {payment.formaPagamento.descricao}
                            </Typography>
                            <Typography variant="body1">
                                {Utils.formatNumber(payment.valor)}
                            </Typography>
                        </Box>
                        <Box py={2}>
                            <Divider />
                        </Box>
                        <Box gridGap="8px">
                            <InfoTile
                                caption="Data"
                                description={formatDate(order.dataPedido)} />
                            <InfoTile
                                caption="Nome"
                                description={order.nomeCliente} />
                        </Box>
                    </>
                )}

                <FabButton
                    appearIn={isPaid}
                    onClick={toOrderPage}>
                    Ir para a página do pedido
                </FabButton>

                <FabButton
                    appearIn={isError || isRefunded}
                    onClick={toMainPage}>
                    Voltar para página inicial
                </FabButton>
            </DialogContent>

            <SocketContext />

        </Dialog>
    )
}
