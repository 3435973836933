import { Box, Grid, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import React, { Component } from "react";
import Chamado from "../../../imagens/chamadogarcon.svg";
import AuthService from "../../../services/AuthService";
import CardGenerico from '../../generic/CardGenerico';
import CustomButton from '../../generic/CustomButton';

const TOTAL_TENTATIVAS = 10

class ConfirmaChamado extends Component {


    constructor(props) {
        super(props);
        this.state = {
            statusImpressao: 'PENDENTE',
            tentativas: TOTAL_TENTATIVAS,
            interval: null,
            percentual: 0.0,
            chamado: (this.props.location.state ? this.props.location.state.chamado : null) 
        }

        this.consultaStatusImpressaoUltimoChamado = this.consultaStatusImpressaoUltimoChamado.bind(this)
        this.finalizarInterval = this.finalizarInterval.bind(this)
        this.handleClosePage = this.handleClosePage.bind(this)
    }

    componentDidMount() {
        let interval = setInterval(this.consultaStatusImpressaoUltimoChamado, 3000);
        this.setState({ interval })
    }

    finalizarInterval() {
        let { interval } = this.state
        clearInterval(interval)
        this.setState({ interval: null })
        console.log("limpando interval")
    }

    consultaStatusImpressaoUltimoChamado() {
        let { tentativas, percentual } = this.state
        if (tentativas <= 0) {
            //tentativas esgotadas, sem confirmação
            this.setState({ statusImpressao: 'ESGOTADO' })
            this.finalizarInterval()
            return;
        }
        percentual = percentual + (100.0 / TOTAL_TENTATIVAS)
        tentativas -= 1;
        this.setState({ tentativas, percentual })

        console.log("tentativas", tentativas)
        console.log("percentual", percentual)

        let { chamado } = this.state;
        let service = new AuthService();
        service
            .get(`/chamado/${chamado.id}`)
            .then(chamado => {
                let statusImpressao = chamado.statusImpressao

                this.setState({ statusImpressao })

                console.log('Status impressao', statusImpressao)

                if (statusImpressao != 'PENDENTE') {
                    this.finalizarInterval()
                }
            }).catch(err => {
                console.log('Erro',err)
            })
    }

    handleClosePage() {
        this.finalizarInterval()
        this.props.history.replace("/");
    }


    render() {
        const { percentual, statusImpressao } = this.state
        let identificador = this.state.chamado

        return (
            <React.Fragment>
            
                <CardGenerico
                cardContent={
                    <Grid container>
                    <Grid item style={{ textAlign: 'center', marginTop: "20%" }} xs={12}>
                        <Grid width="100%">
                            <Box style={{ margin: "0 auto" }}>
                                <img src={Chamado} style={{ maxHeight: "25vh" }} />
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid item style={{ textAlign: 'center', marginTop: "10%" }} xs={12}>
                        <Typography color="primary">
                            <strong>O Garçom está a caminho <br /> da sua mesa (Mesa: {identificador.numeroIdentificador}).
                        </strong>
                        </Typography>
                    </Grid>

                    <AguardandoConfirmacao percentual={percentual} statusImpressao={statusImpressao} />
                    </Grid>
                } 
                cardActions={
                    <CustomButton variant="contained" color="secondary"
                    fullWidth onClick={() => this.handleClosePage()}
                    disabled={statusImpressao === 'PENDENTE'}>
                    Voltar ao cardápio
                    </CustomButton>
                }>
                   
                
                </CardGenerico>
                 

            </React.Fragment>
        );
    }
}

function AguardandoConfirmacao(props) {
    return (
        <React.Fragment>

            <List style={{ width: '100%' }} >
                <ListItem>
                    <ListItemText
                        primary="Chamado recebido"
                    /> 
                    <ListItemSecondaryAction>
                        <CheckIcon color="primary" />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Notificando o garçom"
                    />
                    <ListItemSecondaryAction>

                        {props.statusImpressao === 'PENDENTE' ? (<CircularProgress size={30} color="primary" />) : null}
                        {props.statusImpressao === 'IMPRESSO' ? (<CheckIcon />) : null}

                    </ListItemSecondaryAction>
                </ListItem>

            </List>

            {props.statusImpressao === 'IMPRESSO' ? (
                <React.Fragment>
                    <Box style={{ margin: "0 auto" }} pt={2} >
                        <Typography color="primary"  >
                            O garçom está indo para a sua mesa!
                    </Typography>
                    </Box>
                </React.Fragment>
            ) : null}

            {props.statusImpressao === 'ESGOTADO' ? (
                <React.Fragment>
                    <Box style={{ margin: "0 auto" }} pt={2} >
                        <Typography color="error" align="center"  >
                            Infelizmente o garçom não conseguiu ver nossa mão erguida.
                           <br />
                            Tente você, talvez ele te veja.
                        </Typography>
                    </Box>
                </React.Fragment>
            ) : null}


        </React.Fragment>
    )
}

export default ConfirmaChamado;