import React from "react"
import { Box, useTheme } from "@material-ui/core"

export function HeaderCover({ empresa }) {
    const theme = useTheme()
    const styles = {
        cover: {
            height: '250px',
            overflow: 'hidden',
            backgroundColor: theme.palette.primary.main,
        },
        coverImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        }
    }

    return (
        <Box className="header-cover" style={{ ...styles.cover }}>
            {!!empresa?.urlCover && (
                <picture>
                    <source
                        media="(min-width: 600px)"
                        srcSet={`https://img.imageboss.me/parseint/cover:center/1200x300/${empresa.urlCover}`} />
                    <img
                        alt=""
                        loading="lazy"
                        style={styles.coverImage}
                        src={`https://img.imageboss.me/parseint/cover:center/600x300/${empresa.urlCover}`} />
                </picture>
            )}
        </Box>
    )
}