import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, MobileStepper } from "@material-ui/core";
import PromoChip from '../generic/PromoChip'
import Utils, { converterImagemWithSize, converterURLImagemProduto } from '../../utils/Utils'
import InformacoesNutricionaisProduto from '../generic/InformacoesNutricionaisProduto'
import { trunkStr } from '../../utils/Utils'
import SwipeableViews from 'react-swipeable-views';
import { Dialog, DialogActions } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: "100%",
        overflow: "hidden",
        maxHeight: "29vh",
    },

    imageSwap: {
        width: "100%",
        overflow: "hidden",
        maxHeight: "270px",
        objectFit: "cover"
    },

    imagem: {
        maxWidth: "100%",
        margin: "0 auto",
        maxHeight: "25vh",
    },
    descricaoProduto: {
        textTransform: 'uppercase',
        fontFamily: "Bebas Neue",
        fontSize: "1.8em",
        overflowWrap: "anywhere",
    }, link: {
        color: theme.palette.primary.main,
        whiteSpace: "nowrap",
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
}));

function ImagemProduto(props) {
    const { produto } = props
    const classes = useStyles()
    const [openInfoProduto, setOpenInfoProduto] = useState(false)

    return (
        <div>

            {produto.imagens && produto.imagens.length > 0 ? (
                <Imagens produto={produto} />
            ) : (
                <Box display="flex" justifyContent="center" style={{ margin: "0 auto" }}>
                    <img style={{ maxHeight: "15vh" }} alt={`${produto.grupoProduto.tipoGrupoProduto.descricao}`}
                        src={`/assets/images/iconsCircles/${produto.grupoProduto.tipoGrupoProduto.imagem}`} />
                </Box>
            )}

            <Box display="flex" pr={2} pl={2} pt={1} gridGap="8px">
                <Box flexGrow={1} >
                    <Typography className={classes.descricaoProduto} color="primary" variant="h2" component="h2">
                        {produto.nome}
                    </Typography>
                </Box>
                <Box flexShrink={0} display="flex" alignItems="center" >
                    {produto.preco > 0 ? (
                        produto.promocaoAtiva ? (
                            <Box pb={1}><PromoChip label={Utils.formatNumber(produto.preco)} /></Box>
                        ) : (
                            <Typography align="right" color="secondary" >
                                {Utils.formatNumber(produto.preco)}
                            </Typography>
                        )
                    ) : null}
                </Box>
            </Box>

            <Box display="flex" pr={2} pl={2} pt={1} alignItems="center" flexDirection="column" gridGap="8px">

                <Box flexGrow={1}>
                    <Typography variant="body1" color="textSecondary" component="p">
                        {trunkStr(produto.descricao, 100)}
                    </Typography>
                </Box>

                <Box>
                    <a className={classes.link} onClick={() => setOpenInfoProduto(true)}>saiba mais</a>
                </Box>

            </Box>

            <InformacoesNutricionaisProduto produto={produto} open={openInfoProduto} close={() => setOpenInfoProduto(false)} />

        </div>
    )
}

function ShowImagem({ handleClose, image }) {
    return (
        <Dialog open={image !== null} onClose={handleClose}>
            <img src={image} alt={`Imagem do produto`} style={{ width: "100%" }} />

            <DialogActions>
                <Box><Button onClick={handleClose}>FECHAR</Button></Box>
            </DialogActions>
        </Dialog>
    )
}

function Imagens({ produto }) {
    const classes = useStyles()
    const [showImagem, setShowImagem] = useState(null)
    const [activeStep, setActiveStep] = useState(0)

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>

                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    style={{ width: '100%' }}
                >
                    {produto.imagens.map((step, index) => (
                        <div key={index}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img
                                    src={converterImagemWithSize(produto.imagens[activeStep])}
                                    alt={'imagem do produto'} className={classes.imageSwap}
                                    onClick={() => setShowImagem(converterURLImagemProduto(produto.imagens[activeStep]))} />
                            ) : null}
                        </div>
                    ))}
                </SwipeableViews>

                <ShowImagem image={showImagem} handleClose={() => setShowImagem(null)} />

                <MobileStepper
                    variant="dots"
                    steps={produto.imagens.length}
                    position="static"
                    activeStep={activeStep}
                />
            </Box>
        </>
    )
}

export default ImagemProduto;
