import React, { useState, useEffect } from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'
import AuthService from '../../../services/AuthService'

const useStyles = makeStyles((theme) => ({
    paperBackground: {
        overflow: 'hidden',
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        borderRadius: theme.spacing(0.5),
        border: '1px solid #ddd',
        backgroundColor: theme.palette.personal.paperBackground,

        '& img': {
            maxWidth: '100%',
        }
    },
}))

export function MensagemDelivery() {
    const classes = useStyles()
    const [mensagem, setMensagem] = useState('')

    useEffect(() => {
        const idEmpresa = AuthService.getProfile()?.empresa?.id

        if (idEmpresa) {
            const service = new AuthService()
            service
                .get(`/empresa/getOnly/${idEmpresa}?atributos=descricaoDelivery`)
                .then(resp => setMensagem(resp.descricaoDelivery))
                .catch(err => console.error(err))
        }
    }, [])

    if (!mensagem) {
        return null
    }

    return (
        <Box display="flex" p={2} justifyContent="center" className={classes.paperBackground}>
            <Typography align="center" variant="body2" color="textSecondary" component="div">
                <div dangerouslySetInnerHTML={{ __html: mensagem }} />
            </Typography>
        </Box>
    )
}

export default MensagemDelivery
