import { useEffect } from 'react'
import { io } from 'socket.io-client'

export default function SocketIO({ addEvents, url, query }) {

    useEffect(() => {
        console.log('Iniciando websocket', url)

        const socket = io(url, {
            query,
            transports: ['websocket'],
        })

        console.log('Socket instanciado', socket)

        socket.on('connect', () => {
            console.log('connect SocketIO', socket.id)
        })

        socket.on('disconnect', (msg) => {
            console.log('disconnect SocketIO', socket.id)
            console.log("Disconnection message", msg)
        })

        addEvents(socket)

        return () => {
            if (socket) {
                socket.disconnect()
            }
        }
        // eslint-disable-next-line
    }, [])

    return null
}
