/**
 * Envia uma mensagem na sala atual do socket
 * @param {*} currentState 
 * @param {*} action 
 * @returns 
 */
export const sendMessageSocket = (currentState, action) => {
    const { socketContext } = currentState
    const { websocket } = socketContext
    const { message } = action

    //verifica o status do socket
    const readyState = websocket.connected
    if (!!readyState) {
        message.room = websocket.room
        websocket.emit('sendMessageSocket', message)
    } else {
        console.log('Socket não aberto - msg não enviada. Status', readyState)
    }

    return {
        ...currentState,
        socketContext: { websocket }
    }
}

export const updateSocket = (currentState, action) => {
    const { websocket } = action

    return {
        ...currentState,
        socketContext: { websocket }
    }
}

export const initialStateSocket = () => {
    return { websocket: {} }
}