import React from 'react'
import { InputMask } from './InputMask'

export function CreditCardNumberInput({ ...other }) {

    return (
        <InputMask
            {...other}
            masks={["#### #### #### ####"]}
            placeholder="xxxx xxxx xxxx xxxx"
        />
    )
}

export function ExpyreDateInput({ ...other }) {

    return (
        <InputMask
            {...other}
            masks={["## / ##"]}
            placeholder="xx / xx"
        />
    )
}

export function CpfCnpjInput({ ...other }) {
    return (
        <InputMask
            {...other}
            masks={["###.###.###-##", "##.###.###/####-##"]}
            placeholder="xxx.xxx.xxx-xx"
        />
    )
}

export function CVVInput({ ...other }) {

    return (
        <InputMask
            {...other}
            masks={["###"]}
            placeholder="xxx"
        />
    )
}

export function PhoneInput({ ...other }) {

    return (
        <InputMask
            {...other}
            masks={["(##) ####-####", "(##) # ####-####"]}
            placeholder="(xx) xxxx-xxx"
        />
    )
}

export function PostalCodeInput({ ...other }) {

    return (
        <InputMask
            {...other}
            masks={["#####-###"]}
            placeholder="xxxxx-xxx"
        />
    )
}