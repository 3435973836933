import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function getSteps(pedido) {
    if (pedido.identificador.tipo === "DELIVERY") {
        return ['Recebido', 'Confirmado', 'Enviado', 'Entregue']
    } else {
        return ['Recebido', 'Confirmado'];
    }
}
const useQontoStepIconStyles = makeStyles(theme => ({
    root: {
        color: "#eaeaf0",
        display: 'flex',
        alignItems: 'center',
        width: 8,
        height: 8,
    },
    active: {
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: '50%',
        backgroundColor: 'transparent',
    },
    circle: {
        border: `2px solid #eaeaf0`,
        borderRadius: '50%',
        width: 8,
        height: 8,
        backgroundColor: 'currentColor',
    },
    circleCompleted: {
        color: "transparent",
        border: `2px solid ${theme.palette.secondary.main}`,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        zIndex: 1,
        fontSize: 18,
    },
    //classe CSS para editar a exibição do estatus do pedido na Stepper 
    //Esta classe edita somente quando é acionada a condição vertical do stepper
    vertical: {
        paddingTop: 1,
        paddingBottom: 1,
        marginTop: '2px',
        marginBottom: '8px',
    },

}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div>
            {(completed || active) ? <CheckCircleIcon color='secondary' /> : <div className={classes.circle} />}
        </div>
    );
}

export default function CustomizedSteppers(props) {
    const steps = getSteps(props.pedido);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.up('sm'));

    var classes = useQontoStepIconStyles();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" paddingTop={1}>
            <Typography>Status do seu pedido</Typography>

            <Stepper classes={{ vertical: classes.vertical }} orientation={mobile ? "horizontal" : "vertical"} activeStep={props.activeStep} connector={null}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={QontoStepIcon} >
                            <Typography>
                                {label}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

        </Box>
    );
}
