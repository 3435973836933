import React, { memo } from 'react'

import { Box, Typography } from '@material-ui/core'

export const InfoTile = memo(function InfoTile({ icon, caption, description, subDescription }) {
    return (
        <Box display="flex" alignItems="center" gridGap="8px">
            {!!icon && icon}
            <Box>
                <Typography variant="caption">{caption}</Typography>
                <Typography variant="body1">{description}</Typography>
                {!!subDescription && (
                    <Typography variant="caption">{subDescription}</Typography>
                )}
            </Box>
        </Box>
    )
})