import React, { useEffect, useState } from 'react'

import { Box, Button, Fade, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AuthService from '../../services/AuthService'
import { calcularValorTotalPedido, getCart, getPerfile } from '../../utils/PedidoUtil'
import Utils from '../../utils/Utils'
import { IconByPaymentType } from '../generic/IconByPaymentType'
import { InfoTile } from '../generic/InfoTile'

const useStyles = makeStyles((theme) => ({
    reviewOrder: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        maxHeight: '100vh',
        zIndex: 3,
    },

    reviewOrderBackdrop: {
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },

    reviewOrderContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        margin: '0 auto',
        maxWidth: theme.breakpoints.values.sm,
        padding: theme.spacing(2),
        animation: '$scaleUpFromBottom 0.4s forwards',
        transform: 'scaleY(0)',
        transformOrigin: 'bottom',
        borderRadius: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 0`,
    },
    '@keyframes scaleUpFromBottom': {
        'from': {
            transform: 'translateY(100%)',
        },
        'to': {
            transform: 'translateY(0)',
        },
    },
}))

export function ReviewOrderDialog({ pessoa, formaPagamento, troco, valorTaxaEntrega = 0, open, onClose, onConfirm }) {
    const classes = useStyles()
    const [tempoMinPreparoText, setTempoMinPreparoText] = useState('')
    const destinoEndereco = `${pessoa.endereco}, ${pessoa.numero}`
    const destinoEnderecoResto = `${pessoa.bairro} - ${pessoa.municipio.nome} / ${pessoa.estado.sigla}`

    const cart = getCart()
    const value = valorTaxaEntrega + calcularValorTotalPedido(cart.pedido)

    useEffect(() => {
        const service = new AuthService()
        const empresa = getPerfile().empresa

        service.get(`/empresa/getOnly/${empresa.id}?atributos=tempoMinPreparo,tempoMaxPreparo`)
            .then(resp => {
                const tempoMinPreparo = resp.tempoMinPreparo
                const tempoMaxPreparo = resp.tempoMaxPreparo

                setTempoMinPreparoText(`${tempoMinPreparo}-${tempoMaxPreparo}`)
            }).catch(err => console.error(err))
    }, [])

    function handleConfirm() {
        onClose()
        onConfirm()
    }

    return (
        <Fade in={open} mountOnEnter unmountOnExit>
            <Box className={classes.reviewOrder}>
                <Box className={classes.reviewOrderBackdrop} onClick={onClose} />

                <Paper className={classes.reviewOrderContainer}>
                    <Typography align="center" style={{ marginBottom: '16px', fontWeight: 500 }}>
                        Revise seu pedido
                    </Typography>

                    <Box display="flex" flexDirection="column" gridGap="8px" mb={4}>
                        <InfoTile
                            icon={<ScheduleIcon />}
                            caption="Previsão de entrega"
                            description={`${tempoMinPreparoText} minutos`}
                        />
                        <InfoTile
                            icon={<LocationOnIcon />}
                            caption={destinoEnderecoResto}
                            description={destinoEndereco}
                        />
                        <InfoTile
                            icon={<IconByPaymentType type={formaPagamento?.tipo} />}
                            caption={formaPagamento.tipoPagamento !== 'online' ? 'Pagamento' : 'Pagamento online'}
                            description={`${Utils.formatNumber(value)} - ${formaPagamento.descricao}`}
                            subDescription={troco ? `troco para ${Utils.formatNumber(troco)}` : null}
                        />
                    </Box>

                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={handleConfirm}>
                        Confirmar pedido
                    </Button>

                    <Button
                        fullWidth
                        color="secondary"
                        variant="text"
                        onClick={onClose}>
                        Voltar
                    </Button>
                </Paper>
            </Box>
        </Fade>
    )
}