import React, { memo } from 'react'

import CropFreeIcon from '@material-ui/icons/CropFree'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

export const IconByPaymentType = memo(function IconByPaymentType({ type }) {
    if (type === 'DINHEIRO') {
        return <AttachMoneyIcon />
    } else if (type === 'PIX') {
        return <CropFreeIcon />
    } else if (type === 'CREDIT_CARD' || type === 'CARTAO') {
        return <CreditCardIcon />
    } else {
        return <AttachMoneyIcon />
    }
})