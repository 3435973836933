import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    Box,
    Card,
    Grid,
    IconButton,
    Container,
    Fade
} from "@material-ui/core";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import './CardGenerico.css';

const useStyles = makeStyles((theme) => ({
    contentCenter: {
        textAlign: "center"
    },
    contentRight: {
        textAlign: "right"
    },
    contentLeft: {
        textAlign: "left"
    },
    fullScreen: {
        height: "100%",
        borderRadius: 0,
    },
    containerFull: {
        padding: "0",
        height: '100%',
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: theme.palette.primary.main
    }
}));

function CardGenerico(props) {

    const classes = useStyles();

    return (
        <Container maxWidth="sm" fixed className={classes.containerFull}  >

            <CssBaseline />

            <Box className={classes.fullScreen} >

                <Fade in={true} >
                    <Card className={classes.fullScreen}>

                        <Box display="flex" flexDirection="column" className={classes.fullScreen} >

                            <Box pt={0} pl={0} pr={0} >
                                <Grid container >
                                    <Grid item xs={2} className={classes.contentLeft} >
                                        {props.leftButtonClick ? (
                                            <IconButton color="primary" onClick={props.leftButtonClick}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2} className={classes.contentRight} >
                                        {props.rightButtonClick ? (
                                            <IconButton color="primary" onClick={props.rightButtonClick}>
                                                <CloseIcon />
                                            </IconButton>
                                        ) : null}

                                    </Grid>
                                </Grid>
                            </Box>

                            <Box flexGrow={1} style={{ overflowY: "auto" }}>
                                <div style={{ height: "100%" }}>
                                    <Box >
                                        {props.cardMedia}
                                    </Box>

                                    <Box p={1}>
                                        {props.cardContent}
                                        {props.children}
                                    </Box>
                                </div>
                            </Box>

                            <Box pl={1} pr={1} pb={1}>
                                {props.cardActions}
                            </Box>
                        </Box>

                    </Card>
                </Fade>

            </Box>

        </Container>
    );
}

CardGenerico.defaultProps = {
}


export default CardGenerico;