import React, { Component } from "react";
import { Container, withTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, Typography } from "@material-ui/core";
import QrReader from 'react-qr-reader'
import Button from '@material-ui/core/Button';


class MainPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            result: 'Aguardando leitura do qrcode...',
            error: false,
            readQRcode: false
        }
        this.handleCameraClick = this.handleCameraClick.bind(this)
        this.handleCloseCameraClick = this.handleCloseCameraClick.bind(this)

        console.log("abrindo pagina de leitura de qrcode.")
    }

    handleScan = data => {
        if (data) {

            this.setState({
                result: `Lendo ${data}`
            })
            let url = new URL(data)
            let path = url.pathname
            this.props.history.replace(path);
        }
    }

    handleError = err => {
        console.error(err)

        this.setState({
            result: `Erro ao ler qrcode. Tente novamente`,
            error: true
        })

        if (err.message.toLowerCase().indexOf('permission denied') === 0) {
            this.setState({
                result: `Ahh... Não conseguimos ler o qrcode. Aparentemente 
                    não temos permissão para acessar a câmera. 
                    Experimente abrir seu app nativo da câmera ou utilize um app leitor de QRCode`
            })
        } else {
            this.setState({
                result: `Ahh... Não conseguimos ler o qrcode. Aparentemente 
                não temos permissão para acessar a câmera. 
                Experimente abrir seu app nativo da câmera ou utilize um app leitor de QRCode`
            })
        }


        //this.props.history.replace(path);
    }

    handleCameraClick() {
        this.setState({
            readQRcode: true,
            error: false,
            result: 'Aguardando leitura do qrcode...',
        })
    }

    handleCloseCameraClick() {
        this.setState({
            readQRcode: false
        })
    }


    render() {
        const { readQRcode, error } = this.state
        const backgroundColor = this.props.theme.palette.primary.main
        const fontColorConstrast = this.props.theme.palette.getContrastText(backgroundColor)


        return (
            <div style={{ backgroundColor: backgroundColor }}>

                <Container maxWidth="sm" style={{ height: '100vh' }} >
                    <CssBaseline />

                    {readQRcode ? (
                        <React.Fragment>
                            <Box display="flex" justifyContent="center" alignItems="center" >
                                <img src="/assets/images/AnimacaoQrPedir.gif" style={{ 'width': "60%", 'height': '60%' }} alt="" />
                            </Box>
                            <Box display="flex" justifyContent="center" style={{ color: 'white' }} p={3}>
                                {this.state.result}
                            </Box>

                            {error ? (
                                <Typography variant="overline" display="block" style={{ marginTop: '5%' }} >

                                </Typography>
                            ) : (
                                <QrReader
                                    delay={300}
                                    onError={this.handleError}
                                    onScan={this.handleScan}
                                    style={{ width: '100%' }}
                                />
                            )}

                            <Box display="flex" justifyContent="center"   >
                                <Box p={1} >
                                    <Button style={{ fontFamily: "Bebas Neue", fontSize: "18px" }} variant="contained" size="large" onClick={this.handleCloseCameraClick} >
                                        Tentar novamente
                                    </Button>
                                </Box>
                            </Box>
                        </React.Fragment>

                    ) : (
                        <QRCodePrevStep abrirCameraClick={this.handleCameraClick} fontColor={fontColorConstrast} />
                    )}

                </Container>

            </div>
        );
    }
}

function QRCodePrevStep(props) {

    const fontColorConstrast = props.fontColor

    return (
        <React.Fragment>
            <Box display="flex" justifyContent="center">
                <Box style={{ margin: '0 auto', paddingTop: "30%" }}>
                    <img src='/assets/images/lerQrcode.png' sizes="50vw" style={{ width: "200px" }} alt="" />
                </Box>
            </Box>

            <Box display="flex" justifyContent="center" style={{ marginTop: '5%' }}>
                <Typography style={{ fontFamily: "Bebas Neue", fontSize: "40px", color: fontColorConstrast }} variant="h4" component="h1" gutterBottom color="secondary">
                    BEM VINDO
                </Typography>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" >
                <Box p={1} >
                    <Typography style={{ fontFamily: "Roboto", fontWeight: "400", color: fontColorConstrast }} color="secondary" align="center">
                        Para acessar o cardápio é necessário escanear o qrcode.
                    </Typography>
                </Box>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" >
                <Box p={1} >
                    <Button variant="contained" size="large" style={{ fontFamily: "Bebas Neue", fontSize: "18px" }} onClick={props.abrirCameraClick}>
                        ABRIR A CÂMERA
                    </Button>
                </Box>
            </Box>

        </React.Fragment>
    );
}


export default withTheme(MainPage);



