import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'

/**
 * Contexto para compartilhar os erros do formulário com os componentes filhos.
 */
export const FormContext = createContext(null)

/**
 * @typedef {Object} Validator
 * @property {Function} name - Função para válidar o input com determinado name.
 */

/**
 * Componente de formulário que permite a validação de campos e exibição de erros.
 * 
 * @param {Object} props - Propriedades do componente.
 * @param {Function} props.onSubmit - Função chamada quando o formulário é submetido e todos os campos são válidos.
 * @param {Validator} props.validator - Objeto de validação onde cada chave é o nome do campo e o valor é uma função de validação.
 * @param {ReactNode} props.children - Componentes filhos que representam os campos do formulário.
 * @param {Object} props.other - Outras propriedades passadas para o formulário (como `className`, `id`, etc.).
 * 
 * @returns {JSX.Element} O componente do formulário.
 * 
 * @example
 * const validator = useCallback({
 *   ccv: (value) => { 
 *     if (value.length !== 3) {
 *         return 'CVV inválido'
 *     }
 * 
 *     return true
 *   }
 * }, [])
 * 
 * <FormChef onSubmit={handleSubmit} validator={validator}>
 *   <input name="ccv" />
 * </FormChef>
 */
export function FormChef({ onSubmit, validator, children, ...other }) {
    const formRef = useRef(null)
    const [formErrors, setFormErrors] = useState({})

    const handleKeyDown = useCallback((e) => {
        const { name } = e.target

        setFormErrors(prevState => {
            if (!prevState[name]) {
                return prevState
            }

            const { [name]: _, ...rest } = prevState
            return rest
        })
    }, [])

    useEffect(() => {
        if (!formRef.current) return

        const inputs = formRef.current.querySelectorAll('input')

        inputs.forEach(item => {
            item.addEventListener('keydown', handleKeyDown)
        })

        return () => {
            inputs.forEach(item => {
                item.removeEventListener('keydown', handleKeyDown)
            })
        }
    }, [handleKeyDown])

    function handleSubmit(e) {
        e.preventDefault()

        if (!validator) {
            onSubmit(e)
            return
        }

        let isAllValid = true
        const newErrors = {}

        Object.keys(validator).forEach(item => {
            const { value } = e.target.querySelector(`input[name=${item}]`)
            const validatorFunction = validator[item]
            const error = validatorFunction(value)

            if (typeof error === 'string') {
                isAllValid = false
                newErrors[item] = error
            }
        })

        if (isAllValid) {
            onSubmit(e)
        } else {
            setFormErrors(newErrors)
        }
    }

    return (
        <form
            {...other}
            ref={formRef}
            onSubmit={handleSubmit}>
            <FormContext.Provider value={formErrors}>
                {children}
            </FormContext.Provider>
        </form>
    )
}