import React, { useContext, useEffect, useState } from 'react'

import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'
import { useDispatch, useSelector } from 'react-redux'
import { FormContext } from '../generic/FormChef'
import { CpfCnpjInput, CreditCardNumberInput, CVVInput, ExpyreDateInput, PhoneInput, PostalCodeInput } from '../generic/MaskedInputs'
import { CreditCardViewer } from './CreditCardViewer'

function CreditCardHolder({ form, tipoDelivery, handleChange }) {

    return (
        <>

            <Grid item xs={12}>
                <Typography variant="h6" align="center">
                    Informações do portador
                </Typography>
            </Grid>

            {tipoDelivery !== 'ENTREGAR' && (
                <>
                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            type="number"
                            label="Número"
                            variant="outlined"
                            name="addressNumber"
                            value={form.addressNumber}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            label="CEP"
                            name="postalCode"
                            variant="outlined"
                            value={form.postalCode}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: PostalCodeInput,
                            }} />
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    value={form.email}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange} />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    type="tel"
                    name="mobilePhone"
                    label="Celular"
                    variant="outlined"
                    value={form.mobilePhone}
                    InputProps={{
                        inputComponent: PhoneInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneIcon />
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange} />
            </Grid>

        </>
    )
}

function CreditCard({ form, handleChange }) {
    const formErrors = useContext(FormContext)
    const [rotateCard, setRotateCard] = useState(false)

    useEffect(() => {
        const creditCardFormInputs = document.querySelectorAll('#credit-card-form input')

        function handleFocus(e) {
            setRotateCard(e.target.name === 'ccv' || e.target.name === 'expiryDate')
        }

        creditCardFormInputs.forEach(item => item.addEventListener('focus', handleFocus))

        return () => {
            creditCardFormInputs.forEach(item => item.removeEventListener('focus', handleFocus))
        }
    }, [])

    return (
        <>

            <CreditCardViewer turn={rotateCard} />

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="number"
                    label="Número do cartão"
                    variant="outlined"
                    value={form.number}
                    onChange={handleChange}
                    error={!!formErrors.number}
                    helperText={formErrors.number}
                    InputProps={{
                        inputComponent: CreditCardNumberInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <CreditCardIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    required
                    fullWidth
                    name="expiryDate"
                    label="Validade"
                    variant="outlined"
                    value={form.expiryDate}
                    onChange={handleChange}
                    error={!!formErrors.expiryDate}
                    helperText={formErrors.expiryDate}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: ExpyreDateInput
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    required
                    fullWidth
                    name="ccv"
                    label="CCV"
                    variant="outlined"
                    value={form.ccv}
                    onChange={handleChange}
                    error={!!formErrors.ccv}
                    helperText={formErrors.ccv}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: CVVInput
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="holderName"
                    label="Nome do titular"
                    variant="outlined"
                    value={form.holderName}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    error={!!formErrors.cpfCnpj}
                    helperText={formErrors.cpfCnpj}
                    name="cpfCnpj"
                    label="CPF/CNPJ do titular"
                    variant="outlined"
                    value={form.cpfCnpj}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: CpfCnpjInput
                    }}
                />
            </Grid>

        </>
    )
}

export function CreditCardContent() {
    const dispatch = useDispatch()
    const { form, tipoDelivery } = useSelector(state => state.payment)

    function handleUpdatePayment(newForm) {
        dispatch({ type: 'UPDATE_PAYMENT', data: { form: newForm } })
    }

    function handleChange({ target }) {
        handleUpdatePayment({
            ...form,
            [target.name]: target.value,
        })
    }

    function handleChangeHolderInfo({ target }) {
        handleUpdatePayment({
            ...form,
            creditCardHolderInfo: {
                ...form.creditCardHolderInfo,
                [target.name]: target.value,
            }
        })
    }

    return (
        <Grid id="credit-card-form" container spacing={2}>

            <CreditCard
                form={form}
                handleChange={handleChange} />

            <CreditCardHolder
                form={form.creditCardHolderInfo}
                tipoDelivery={tipoDelivery}
                handleChange={handleChangeHolderInfo} />

        </Grid>
    )
}