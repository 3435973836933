import React, { useEffect } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import GrupoProdutoResponsivoList from "./grupoProdutoResponsivoList"
import { makeStyles } from '@material-ui/core/styles';
import ProdutoDialog from "../../Steps/produtoDialog";
import { useHistory } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector, useDispatch } from 'react-redux'
import AuthService from "../../../services/AuthService";
import { useState } from "react";


const useStyles = makeStyles((theme) => ({
    categoria: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontFamily: "Bebas Neue",
        fontSize: '1.4em'
    },
}))


export default function CardapioList({ grupos, buscandoProdutos }) {

    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()

    const produtoSelecionadoAtual = useSelector(state => state.productState.product)
    const openProduto = useSelector(state => state.productState.open)
    const searchProduct = useSelector(state => state.searchProduct.search)

    const [grupoProdutos, setGruposProdutos] = useState([])

    const setProdutoSelecionadoAtual = (produto) => {
        dispatch({ type: 'OPEN_PRODUCT', product: produto })
    }

    const toogleCart = (open) => {
        dispatch({ type: 'VISIBILITY_CART', open })
    }

    useEffect(() => {
        handleGetProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        toogleCart(!openProduto)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openProduto]);

    useEffect(() => {
        if (produtoSelecionadoAtual) {
            history.push("#produto/" + produtoSelecionadoAtual.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtoSelecionadoAtual]);

    useEffect(() => {
        filterProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchProduct, grupos])

    const handleClickProduto = (produto) => {
        setProdutoSelecionadoAtual(produto)
        clearSearch()
    }

    const handleOnCloseProduto = () => {
        dispatch({ type: 'CLOSE_PRODUCT' })
    }

    const handleGetProduct = () => {
        var url = window.location.hash.split("#produto/")
        let service = new AuthService()

        if (url && url[1]) {
            service.get(`/produto/${url[1]}`)
                .then(produto => {
                    console.log('produto', produto)
                    handleClickProduto(produto)
                })
                .catch(err => {
                    console.error(err)
                })
        }

        history.push("/")
    }

    const filterProducts = () => {
        if (!!grupos && grupos.length > 0) {
            let gruposFiltrados = JSON.parse(JSON.stringify(grupos))
            if (!!searchProduct || searchProduct !== '') {
                gruposFiltrados.map(grupo => {
                    if (!!grupo.produtos) {
                        grupo.produtos = grupo.produtos.filter((produto) => produto.nome.toLowerCase().includes(searchProduct.toLowerCase()))
                    }
                    return null;
                })
            }

            gruposFiltrados = gruposFiltrados.filter((grupo) => grupo.produtos?.length > 0)
            setGruposProdutos(gruposFiltrados || [])
        }
    }

    const clearSearch = () => {
        dispatch({ type: 'SEARCH_PRODUCT', searchProduct: { search: '', open: false } })
    }

    if (buscandoProdutos) {
        return (
            <div className="products-section">
                <SkeletonListProdutos />
            </div>
        )
    }

    if (grupoProdutos.length === 0) {
        return (
            <Box display="block" textAlign="center">
                <Typography
                    variant='body1'
                    display="block"
                    color="primary"
                    style={{
                        fontFamily: "Bebas Neue",
                        fontSize: "30px",
                        fontWeight: "500",
                    }}>
                    Nenhum produto encontrado para a busca atual
                </Typography>
            </Box>
        )
    }

    return (
        <div className="products-section">

            {produtoSelecionadoAtual ? (
                <ProdutoDialog produto={produtoSelecionadoAtual} open={openProduto} handleClose={handleOnCloseProduto} ></ProdutoDialog>
            ) : null}

            {grupoProdutos.map((parentGrupo, index) => (
                <div key={parentGrupo.grupoProduto.id}
                    className="group-section"
                    data-index={index}
                    id={`section_grupo_produto_${parentGrupo.grupoProduto.id}`}>

                    <Box
                        display="block"
                        textAlign="center"
                        marginTop="32px"
                        marginBottom="8px">
                        <Box display="block" textAlign="center">
                            <img src={`/assets/images/iconsLinesColor/${parentGrupo.grupoProduto.tipoGrupoProduto.imagem}`}
                                alt={parentGrupo.grupoProduto.tipoGrupoProduto.descricao}
                                style={{ width: '15vw', maxWidth: '60px', marginBottom: "-10px" }} />
                        </Box>

                        <Typography
                            display="block"
                            variant="h6"
                            component="h2" className={classes.categoria}>{parentGrupo.grupoProduto.descricao}</Typography>
                    </Box>

                    <GrupoProdutoResponsivoList parentGrupo={parentGrupo} setProdutoSelecionado={handleClickProduto} />
                </div>
            ))}
        </div>
    )
}


function SkeletonListProdutos() {
    return (
        <React.Fragment>
            <Skeleton variant="text" />
            <Skeleton variant="rect" width={'100%'} height={60} />
            <Divider />
            <Skeleton variant="rect" width={'100%'} height={60} />
            <Divider />
            <Skeleton variant="rect" width={'100%'} height={60} />
            <Divider />
            <Skeleton variant="rect" width={'100%'} height={60} />

            <Skeleton variant="text" height={20} />
            <Skeleton variant="rect" width={'100%'} height={60} />
            <Divider />
            <Skeleton variant="rect" width={'100%'} height={60} />
            <Divider />
            <Skeleton variant="rect" width={'100%'} height={60} />
            <Divider />
            <Skeleton variant="rect" width={'100%'} height={60} />
        </React.Fragment>
    );
}

