import React, { useEffect, useState } from "react"

import { makeStyles } from '@material-ui/core/styles'
import AuthService from "../../services/AuthService"
import { Box, Typography } from "@material-ui/core"
import StarIcon from '@material-ui/icons/Star'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles((theme) => ({
    mediaRating: {
        cursor: 'pointer',
        color: 'white',
        backgroundColor: '#f9c007',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        position: 'absolute',
        top: '-14px',
        left: 0,
        zIndex: 2,
    },

    totalRating: {
        color: 'white',
        background: '#ea9900',
        whiteSpace: 'nowrap',
        opacity: 0,
        position: 'absolute',
        top: '-14px',
        left: 0,
        padding: '4px 8px 4px 12px',
        borderRadius: theme.spacing(0.5),
        transform: 'translateX(0)',
        transformOrigin: 'left',
        transition: '0.2s',
        zIndex: 1,
    },

    active: {
        '& .arrow-icon': {
            transform: 'rotate(-180deg)',
        },

        '& $totalRating': {
            opacity: 1,
            transform: 'translateX(70px)',
        }
    }
}))

export function StarRating() {
    const classes = useStyles()
    const [active, setActive] = useState(false)
    const [mediaRating, setMediaRating] = useState('')
    const [totalRating, setTotalRating] = useState('')

    useEffect(() => {
        async function fetch() {
            try {
                const service = new AuthService()
                const [media, total] = await Promise.all([
                    service.get('/avaliacaoEmpresa/informacoes/MEDIA'),
                    service.get('/avaliacaoEmpresa/informacoes/TOTAL_AVALIACOES'),
                ])

                if (!media.valor) {
                    return
                }

                const num = Number(media.valor)
                const formated = num.toFixed(1)

                setMediaRating(formated)
                setTotalRating(total?.valor)
            } catch (err) {
                console.log(err)
            }
        }

        fetch()
    }, [])

    if (!mediaRating) {
        return null
    }

    return (
        <Box position="relative" className={active ? classes.active : null}>
            <Box className={classes.mediaRating} onClick={() => setActive(state => !state)}>
                <StarIcon fontSize="small" />
                <Typography variant="body2">{mediaRating}</Typography>
                <ChevronRightIcon className="arrow-icon" fontSize="small" style={{ transition: '0.2s' }} />
            </Box>
            <Box className={classes.totalRating}>
                <Typography variant="body2">{totalRating} avaliações</Typography>
            </Box>
        </Box>
    )
}