import React from "react"
import { Typography, Box, ListItemAvatar, Avatar, ButtonBase } from "@material-ui/core"
import Utils, { trunkStr } from '../../../utils/Utils'
import { makeStyles } from '@material-ui/core/styles'
import { PromoBadge } from "../../generic/PromoBadge"


const useStyles = makeStyles((theme) => ({

    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
        gridGap: theme.spacing(),
        padding: theme.spacing(),
    },

    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        objectFit: 'contain',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    button: {
        overflow: 'hidden',
        backgroundColor: theme.palette.personal.paperBackground,
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        minHeight: "70px",
        maxHeigth: "100px",
        width: '100%',
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shortest,
        }),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(),
        backgroundClip: 'padding-box',
        alignItems: 'flex-start',

        '&:hover': {
            textDecoration: 'none',
            backgroundColor: theme.palette.action.hover,

            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    listProdutos: {
        width: '100%',
        maxHeigth: '100px',
    },

    item: {
        overflowWrap: 'anywhere',
        fontWeight: '500',
        textTransform: 'initial',
    },

    itemDescricao: {
        overflowWrap: 'anywhere',
        textTransform: 'initial',
        marginTop: theme.spacing(0.5),
        lineHeight: 1.2,
    },

    valor: {
        color: theme.palette.primary.main,
        whiteSpace: 'nowrap',
        textTransform: 'initial',
    },

}))

function ComplementsValue({ produto }) {
    const classes = useStyles()

    if (!produto.complementos.length) {
        return (
            <Typography className={classes.valor}>
                Ver opções
            </Typography>
        )
    }

    if (produto.complementos.length === 1) {
        return (
            <Typography className={classes.valor}>
                1 complemento
            </Typography>
        )
    }

    return (
        <Typography className={classes.valor}>
            {produto.complementos.length} complementos
        </Typography>
    )
}

function GrupoProdutoResponsivoList({ parentGrupo, setProdutoSelecionado }) {
    const classes = useStyles()

    function handlePedido(produto) {
        setProdutoSelecionado(produto)
    }

    return (
        <Box className={classes.grid}>

            {parentGrupo.produtos.map((produto, index) => (
                <ButtonBase
                    focusRipple
                    key={index}
                    className={classes.button}
                    onClick={() => handlePedido(produto)} >

                    <Box
                        p={1}
                        display="flex"
                        className={classes.listProdutos}>

                        <Box
                            pr={1}
                            flexGrow={1}
                            textAlign="initial"
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            style={{ gap: '8px' }}
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '80%',
                                    md: '70%',
                                    lg: '60%',
                                }
                            }}>

                            <Box>
                                <Typography
                                    variant="body1"
                                    component="span"
                                    className={classes.item}>
                                    {produto.nome} {produto.promocaoAtiva ? <PromoBadge /> : ''}
                                </Typography>

                                {!!produto.descricao && (
                                    <Typography
                                        display="block"
                                        variant="body2"
                                        color="textSecondary"
                                        className={classes.itemDescricao}>
                                        {trunkStr(produto.descricao, 60)}
                                    </Typography>
                                )}
                            </Box>

                            {!produto.preco ? (
                                <ComplementsValue produto={produto} />
                            ) : (
                                <Typography className={classes.valor}>
                                    {Utils.formatNumber(produto.preco)}
                                </Typography>
                            )}
                        </Box>

                        {produto.imagens && produto.imagens.length > 0 && (
                            <ListItemAvatar>
                                <Avatar
                                    variant="rounded"
                                    src={produto.imagens[0].thumbnailUrl}
                                    className={classes.large} />
                            </ListItemAvatar>
                        )}

                    </Box>
                </ButtonBase>
            ))}

        </Box>
    )
}

export default GrupoProdutoResponsivoList;



