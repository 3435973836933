import React from 'react'
import Routes from './routes'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from "@material-ui/styles"
import MyThemeProvider, { useThemeContext } from "./pages/context/MyThemeContext"
import { Provider } from 'react-redux'
import store from './store'


const App = () => {
  return (
    <div className="App">
      <MyThemeProvider>
        <AppContext />
      </MyThemeProvider>
    </div>
  )
};

const AppContext = function () {
  const { myTheme } = useThemeContext()

  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={myTheme} >
          <Routes />
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  )
}

export default App


