import React, { memo, useEffect, useState } from "react"
import { getPerfile } from '../../../utils/PedidoUtil'
import Utils, { convertWhatsAppNumber } from '../../../utils/Utils'
import AuthService from "../../../services/AuthService"

import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import AlarmOnIcon from '@material-ui/icons/AlarmOn'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'

import { Box, IconButton, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    addressAndSocialMedia: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: '0 16px',
        }
    },
}))

const InfoCard = memo(function InfoCard({ icon, title, description }) {

    return (
        <Box display="flex" flexDirection="column" gridGap="4px" alignItems="center" flex={1}>
            <Box display="flex" alignItems="center" gridGap="4px">
                {icon}
                <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "500" }}>
                    {title}
                </Typography>
            </Box>
            <Typography
                color="primary"
                variant="caption"
                style={{ lineHeight: "1em" }}>
                {description}
            </Typography>
        </Box>
    )
})

// comentado lá no código porém pode ser que alguém queira utiliza-lo
const SocialMedia = memo(function SocialMedia() {
    const profile = getPerfile()
    const whatsNumber = convertWhatsAppNumber(profile.empresa.foneWhatsapp)
    const isDelivery = profile.tipo === 'DELIVERY'

    const redireciona = (local) => {
        if (local === "facebook") {
            window.open(profile.empresa.urlFacebook)
        } else if (local === "instagram") {
            window.open(profile.empresa.urlInstagram)
        } else if (local === "whats") {
            window.open(`https://wa.me/${whatsNumber}?text=Olá, estou utilizando seu cardápio pelo qrpedir`)
        }
    }

    return (
        <Box display="flex" justifyContent="center" gridGap="4px" pb={1}>
            {!!profile.empresa.urlInstagram && (
                <IconButton onClick={() => redireciona("instagram")}>
                    <InstagramIcon />
                </IconButton>
            )}
            {!!profile.empresa.urlFacebook && (
                <IconButton onClick={() => redireciona("facebook")}>
                    <FacebookIcon />
                </IconButton>
            )}
            {isDelivery && whatsNumber ? (
                <IconButton onClick={() => redireciona("whats")}>
                    <WhatsAppIcon />
                </IconButton>
            ) : null}
        </Box>
    )
})

export default function HeaderCardapio() {
    const classes = useStyles()
    const profile = getPerfile()
    const [empresa, setEmpresa] = useState({})
    const [tempoPreparo, setTempoPreparo] = useState('')
    const enderecoSimples = `${profile.empresa.endereco}, ${profile.empresa.numero} - ${profile.empresa.bairro}`

    useEffect(() => {
        async function fetch() {
            try {
                const idEmpresa = AuthService.getProfile().empresa.id
                const service = new AuthService()

                const [respEmpresa, { tempoMinPreparo, tempoMaxPreparo }] = await Promise.all([
                    service.get(`/empresa/${idEmpresa}`),
                    service.get(`/empresa/getOnly/${idEmpresa}?atributos=tempoMinPreparo,tempoMaxPreparo`),
                ])

                setEmpresa(respEmpresa)
                setTempoPreparo(`${tempoMinPreparo}-${tempoMaxPreparo}`)
            } catch (err) {
                console.error(err)
            }
        }

        fetch()
    }, [])

    if (!empresa || !tempoPreparo) {
        return null
    }

    return (
        <>
            <Box display="flex" alignItems="flex-start" margin="0 auto 16px" maxWidth="675px">
                <InfoCard
                    icon={<AlarmOnIcon fontSize="small" color="action" />}
                    title={empresa.tempoRetirada + 'm'}
                    description="retirada"
                />
                <InfoCard
                    icon={<MotorcycleIcon fontSize="small" color="action" />}
                    title={tempoPreparo}
                    description="minutos"
                />
                <InfoCard
                    icon={<MonetizationOnOutlinedIcon fontSize="small" color="action" />}
                    title={Utils.formatNumber(empresa.valorMinimo)}
                    description="mínimo"
                />
            </Box>

            <Box className={classes.addressAndSocialMedia}>
                {/* <SocialMedia /> */}

                <Box display="flex" justifyContent="center" alignItems="center" gridGap="4px" pb={1}>
                    <RoomOutlinedIcon fontSize="small" color="action" />
                    <Box>
                        <Typography variant="caption" color="textSecondary">
                            {enderecoSimples}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}