import { createStore } from 'redux'
import { initialStatePayment, updatePayment } from './paymentStore'
import { initialStateSocket, sendMessageSocket, updateSocket } from './socketStore'
import { initialStateOnlinePayment, updateOnlinePayment } from './onlinePaymentStore'

const INITIAL_STATE = {
    cart: { open: true },
    productState: { product: null, open: false },
    searchProduct: { search: '', open: false },
    payment: initialStatePayment(),
    onlinePayment: initialStateOnlinePayment(),
    socketContext: initialStateSocket(),
}

function reciveAction(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'VISIBILITY_CART':
            return {
                ...state, cart: { open: action.open }
            }

        case 'OPEN_PRODUCT':
            return {
                ...state, productState: { product: action.product, open: true }
            }

        case 'CLOSE_PRODUCT':
            return {
                ...state, productState: { product: null, open: false }
            }

        case 'SEARCH_PRODUCT':
            return {
                ...state, searchProduct: { search: action.searchProduct.search, open: action.searchProduct.open }
            }

        case 'UPDATE_PAYMENT':
            return updatePayment(state, action)

        case 'UPDATE_ONLINE_PAYMENT':
            return updateOnlinePayment(state, action)

        case 'SEND_MESSAGE_SOCKET':
            return sendMessageSocket(state, action)

        case 'UPDATE_SOCKET':
            return updateSocket(state, action)

        default:
            return state
    }
}

const store = createStore(reciveAction)

export default store