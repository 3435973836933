import React, { useEffect, useState } from 'react'

export function removeMask(value) {
    return value.replace(/\D/g, '')
}

function getMatchingMask(rawValue, masks) {
    if (masks.length === 1) {
        return masks[0]
    }

    const index = masks.findIndex((mask, index) => {
        if (masks.length === (index + 1)) {
            return index
        }

        const rawMask = mask.replace(/[^#]/g, '')
        return rawValue.length <= rawMask.length
    })

    return masks[index]
}

function applyMask(value, masks) {
    if (!value) return value

    const rawValue = removeMask(value)
    const mask = getMatchingMask(rawValue, masks)
    const maskLength = mask.length

    let i = 0
    let rawIndex = 0
    let formattedValue = ''

    for (i = 0; i < maskLength; i++) {
        if (mask[i] !== '#' && rawIndex < rawValue.length) {
            formattedValue += mask[i]
        } else if (mask[i] === '#' && rawIndex < rawValue.length) {
            formattedValue += rawValue[rawIndex++]
        }
    }

    return formattedValue
}

/**
 * Componente de entrada com máscara, que aplica a máscara correspondente ao valor digitado.
 * 
 * @param {string} [props.value] - O valor inicial a ser mostrado no campo.
 * @param {string[]} [props.masks] - Um array de máscaras a serem aplicadas ao valor do input. Cada máscara é composta por números e `#`, onde `#` representa um dígito.
 * @param {Function} [props.onChange] - Função que é chamada toda vez que o valor do input é alterado.
 * @param {Object} [props.other] - Outras propriedades passadas para o campo de entrada (como `className`, `id`, etc.).
 * 
 * @returns {JSX.Element} O componente de entrada com máscara.
 * 
 * @example
 * <InputMask
 *   value="1234567890"
 *   masks={['(###) ###-####', '###-###-####']}
 *   onChange={handleChange}
 * />
 */
export function InputMask({ masks = [], onChange, ...other }) {
    const { inputRef: _, ...otherProps } = other
    const [value, setValue] = useState('')

    useEffect(() => {
        setValue(applyMask(other.value, masks))
    }, [other, masks])

    return (
        <input
            {...otherProps}
            value={value}
            onChange={onChange} />
    )
}