import React, { useEffect, useState } from 'react'
import AuthService from '../../services/AuthService'

function TempoPreparo() {
    const [tempoMinPreparoText, setTempoMinPreparoText] = useState('carregando...')

    useEffect(() => {
        const service = new AuthService()
        const idEmpresa = AuthService.getProfile().empresa.id

        service.get(`/empresa/getOnly/${idEmpresa}?atributos=tempoMinPreparo,tempoMaxPreparo`)
            .then(resp => {
                const tempoMinPreparo = resp.tempoMinPreparo
                const tempoMaxPreparo = resp.tempoMaxPreparo

                setTempoMinPreparoText(`Tempo de entrega ${tempoMinPreparo}m - ${tempoMaxPreparo}m`)
            }).catch(err => console.error(err))
    }, [])

    return (
        <>
            {tempoMinPreparoText}
        </>
    )
}

export default TempoPreparo
